import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { trans } from 'matice';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Paper, Typography } from '@mui/material';
import AuthLayout from '~js/layouts/AuthLayout';
import CtaButton from '~js/components/CtaButton';
import TextInput from '~js/components/TextInput';
import toolsLogo from '~/assets/images/loyalty-built-logo.png';

const LoginPage = () => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const [loading, setLoading] = useState(false);

    const goToForgotPasswordPage = () => router.get(route('ForgotPassword'));

    const onSubmit = (data) => {
        router.post(route('doLoginWithEmailAndPassword'), data, {
            onStart: () => setLoading(true),
            //onFinish: () => setLoading(false),
            onError: (e) => {
                setLoading(false);
                if (!!e?.generic) {
                    toast.error(e.generic);
                    return;
                }
                Object.keys(e).forEach((key) => {
                    setError(key, { type: 'custom', message: e[key] });
                });
            },
        });
    };
    return (
        <>
            <Head title={trans('auth.login.title')} />
            <AuthLayout>
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: 750,
                                p: 8,
                            }}
                        >
                            <Box component="img" src={toolsLogo} maxWidth="194px" alt={'Logo tools loyalty'} mb={3} />
                            {/*  <Typography variant="h6" mt={3}>
                                {trans('auth.login.title')}
                            </Typography> */}
                            <Box width="100%" maxWidth="400px">
                                <Controller
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: trans('formValidation.insertValidEmail'),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            id={'email'}
                                            autoComplete={'email'}
                                            label={trans('auth.views.profile.email')}
                                            placeholder={trans('auth.views.profile.email-plc')}
                                            helperText={errors?.email?.message}
                                            error={!!errors?.email}
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            id={'password'}
                                            type="password"
                                            label="Password"
                                            autoComplete={'current-password'}
                                            placeholder={trans('auth.login.passwordPlaceholder')}
                                            helperText={errors?.password?.message}
                                            error={!!errors?.password}
                                            {...field}
                                        />
                                    )}
                                />
                                <CtaButton
                                    disabled={loading}
                                    type="button"
                                    sx={{ px: 0, textDecoration: 'underline' }}
                                    onClick={goToForgotPasswordPage}
                                >
                                    {trans('auth.login.passwordLost')}
                                </CtaButton>
                            </Box>
                            <CtaButton type="submit" variant="contained" sx={{ px: 6, mt: 6 }} loading={loading}>
                                {trans('auth.login.title')}
                            </CtaButton>
                        </Paper>
                    </Box>
                </Box>
            </AuthLayout>
        </>
    );
};

export default LoginPage;
