import React, { forwardRef, useState } from 'react';
import { alpha, Box, FormControl, FormHelperText, InputLabel, Tooltip } from '@mui/material';

const ColorPickerInput = forwardRef(
    ({ value, label = null, error, helperText, tooltipText = null, sx = {}, id, ...other }, ref) => {
        const handleChange = (event) => {
            const newColor = event.target.value;
            other.onChange(newColor);
        };

        return (
            <FormControl variant="outlined" fullWidth error={error}>
                {label && (
                    <InputLabel
                        shrink
                        htmlFor="color-picker-input"
                        sx={{
                            marginTop: '10px',
                            marginLeft: '-8px',
                            color: '#5E6366',
                            '&.Mui-focused': {
                                color: error ? (theme) => theme.palette.error.main : '#5E6366 !important',
                            },
                        }}
                    >
                        {label}
                    </InputLabel>
                )}
                <Tooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
                    <Box
                        sx={[
                            {
                                marginTop: '25px',
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 12px',
                                border: '1px solid #5E6366',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                fontSize: 16,
                                cursor: 'pointer',
                                position: 'relative',
                            },
                            error && {
                                boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                                borderColor: (theme) => theme.palette.error.main,
                                borderRadius: '8px',
                            },
                            sx,
                        ]}
                    >
                        <input
                            type="color"
                            id={id}
                            value={value}
                            onChange={handleChange}
                            ref={ref}
                            style={{
                                width: '30px',
                                height: '30px',
                                border: 'none',
                                cursor: 'pointer',
                                padding: 0,
                                margin: 0,
                                backgroundColor: 'transparent',
                                appearance: 'none',
                                position: 'absolute',
                            }}
                            {...other}
                        />
                        <input
                            type="text"
                            value={value}
                            onChange={handleChange}
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                                fontSize: '16px',
                                cursor: 'pointer',
                                paddingLeft: '40px',
                            }}
                            {...other}
                            disabled
                        />
                        <Box
                            onClick={() => document.getElementById(id).click()}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}
                        />
                    </Box>
                </Tooltip>
                <Box display={'flex'} flexDirection={'row'} mb={2}>
                    <FormHelperText sx={{ width: '75%', color: '#8B8D97!important' }}>
                        {helperText ? helperText : ' '}
                    </FormHelperText>
                </Box>
            </FormControl>
        );
    },
);

export default ColorPickerInput;
