import React from 'react';
import { Box, Typography } from '@mui/material';
import { trans } from 'matice';
import { Controller, useFormContext } from 'react-hook-form';

import TextInput from '~/js/components/TextInput';
import ChipsTextInput from '~/js/components/ChipsTextInput';
import CtaButton from '~/js/components/CtaButton';
import ColorPickerInput from '~/js/components/ColorPickerInput';
import SelectInput from '~/js/components/SelectInput';
import {
    TOUCHPOINT_BORDER_RADIUS_SELECT_OPTIONS,
    TOUCHPOINT_FONTS_SELECT_OPTIONS,
} from '~/js/constants/touchpointSelectOptions';

const TouchpointWebsiteForm = ({ onCancelPress, loading, touchpointToEdit }) => {
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext();

    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    my: 4,
                    p: 2,
                    border: '1px solid rgba(217, 217, 217, 1)',
                    borderRadius: '8px',
                }}
            >
                <Typography sx={{ mb: 4 }}>Website</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    <Box sx={{ flex: 1, minWidth: 250 }}>
                        <Controller
                            control={control}
                            name="name"
                            shouldUnregister
                            defaultValue={touchpointToEdit?.name || ''}
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            render={({ field }) => (
                                <TextInput
                                    label={trans('touchpoint.name')}
                                    helperText={errors?.name?.message}
                                    error={!!errors?.name}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ flex: 1, minWidth: 250 }}>
                        <Controller
                            control={control}
                            name="domain"
                            shouldUnregister
                            defaultValue={touchpointToEdit?.domain || ''}
                            rules={{
                                required: trans('formValidation.requiredField'),
                                pattern: {
                                    value: /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: trans('formValidation.invalidDomain'),
                                },
                            }}
                            render={({ field }) => (
                                <TextInput
                                    label={trans('touchpoint.domain')}
                                    helperText={errors?.domain?.message}
                                    error={!!errors?.domain}
                                    {...field}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ flex: 1, minWidth: 250 }}>
                        <Controller
                            control={control}
                            name="urls"
                            shouldUnregister
                            defaultValue={touchpointToEdit?.urls || []}
                            rules={{
                                required: trans('formValidation.requiredField'),
                                validate: (value) => {
                                    const pathPattern = /^(\/[a-zA-Z0-9-._~%!$&'()*+,;=:@/]*)$/;
                                    return (
                                        value.every((url) => pathPattern.test(url)) ||
                                        trans('formValidation.invalidPath')
                                    );
                                },
                            }}
                            render={({ field }) => (
                                <ChipsTextInput
                                    tooltipText={trans('touchpoint.urlTooltip')}
                                    label={trans('touchpoint.url')}
                                    helperText={errors?.urls?.message}
                                    error={!!errors?.urls}
                                    onChange={field.onChange}
                                    value={field.value}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: 'rgba(250, 250, 250, 1)',
                    my: 4,
                    p: 2,
                    border: '1px solid rgba(217, 217, 217, 1)',
                    borderRadius: '8px',
                }}
            >
                <Typography sx={{ mb: 4 }}>{trans('touchpoint.theme')}</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                    <Box sx={{ flex: 1 }}>
                        <Typography
                            sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                color: 'rgba(134, 134, 134, 1)',
                                fontSize: 12,
                            }}
                        >
                            {trans('touchpoint.colors')}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 2, flexWrap: 'wrap' }}>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name="primaryColor"
                                    shouldUnregister
                                    defaultValue={touchpointToEdit?.theme?.palette?.primary || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <ColorPickerInput
                                            id="primaryColor"
                                            label={trans('touchpoint.primaryColor')}
                                            helperText={errors?.primaryColor?.message}
                                            error={!!errors?.primaryColor}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name="secondaryColor"
                                    shouldUnregister
                                    defaultValue={touchpointToEdit?.theme?.palette?.secondary || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <ColorPickerInput
                                            id="secondaryColor"
                                            label={trans('touchpoint.secondaryColor')}
                                            helperText={errors?.secondaryColor?.message}
                                            error={!!errors?.secondaryColor}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Controller
                                    control={control}
                                    name="textColor"
                                    shouldUnregister
                                    defaultValue={touchpointToEdit?.theme?.palette?.text || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <ColorPickerInput
                                            id="textColor"
                                            label={trans('touchpoint.textColor')}
                                            helperText={errors?.textColor?.message}
                                            error={!!errors?.textColor}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 2, mt: 2, flexWrap: 'wrap' }}>
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: 'rgba(134, 134, 134, 1)',
                                        fontSize: 12,
                                        mb: 2,
                                    }}
                                >
                                    {trans('touchpoint.typography')}
                                </Typography>
                                <Controller
                                    control={control}
                                    name="font"
                                    shouldUnregister
                                    defaultValue={touchpointToEdit?.theme?.font || ''}
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <SelectInput
                                            label={trans('touchpoint.font')}
                                            selectOptions={TOUCHPOINT_FONTS_SELECT_OPTIONS}
                                            helperText={errors?.font?.message}
                                            error={!!errors?.font}
                                            {...field}
                                        />
                                    )}
                                />
                                {(watch('font') || touchpointToEdit?.theme?.font) && (
                                    <CtaButton
                                        onClick={() =>
                                            window.open(watch('font') || touchpointToEdit?.theme?.font, '_blank')
                                        }
                                    >
                                        {trans('touchpoint.fontPreview')}
                                    </CtaButton>
                                )}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <Typography
                                    sx={{
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold',
                                        color: 'rgba(134, 134, 134, 1)',
                                        fontSize: 12,
                                        mb: 2,
                                    }}
                                >
                                    {trans('touchpoint.radius')}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, mt: 2, flexWrap: 'wrap' }}>
                                    <Controller
                                        control={control}
                                        name="radiusCta"
                                        shouldUnregister
                                        defaultValue={touchpointToEdit?.theme?.radius?.cta || ''}
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                        }}
                                        render={({ field }) => (
                                            <SelectInput
                                                label={trans('touchpoint.cta')}
                                                selectOptions={TOUCHPOINT_BORDER_RADIUS_SELECT_OPTIONS}
                                                helperText={errors?.radiusCta?.message}
                                                error={!!errors?.radiusCta}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="radiusModal"
                                        shouldUnregister
                                        defaultValue={touchpointToEdit?.theme?.radius?.modal || ''}
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                        }}
                                        render={({ field }) => (
                                            <SelectInput
                                                label={trans('touchpoint.modal')}
                                                selectOptions={TOUCHPOINT_BORDER_RADIUS_SELECT_OPTIONS}
                                                helperText={errors?.radiusModal?.message}
                                                error={!!errors?.radiusModal}
                                                {...field}
                                            />
                                        )}
                                    />
                                    <Controller
                                        control={control}
                                        name="radiusBox"
                                        shouldUnregister
                                        defaultValue={touchpointToEdit?.theme?.radius?.box || ''}
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                        }}
                                        render={({ field }) => (
                                            <SelectInput
                                                label={trans('touchpoint.questionBox')}
                                                selectOptions={TOUCHPOINT_BORDER_RADIUS_SELECT_OPTIONS}
                                                helperText={errors?.radiusBox?.message}
                                                error={!!errors?.radiusBox}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, mt: 4, justifyContent: 'space-between' }}>
                {!touchpointToEdit && (
                    <CtaButton variant="outlined" onClick={onCancelPress} disabled={loading}>
                        {trans('global.cancel')}
                    </CtaButton>
                )}
                <CtaButton variant="contained" type="submit" loading={loading}>
                    {!!touchpointToEdit ? trans('global.saveChanges') : trans('touchpoint.addTouchpoint')}
                </CtaButton>
            </Box>
        </>
    );
};

export default TouchpointWebsiteForm;
