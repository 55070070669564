import React, { forwardRef, useState } from 'react';
import { alpha, Box, Chip, FormControl, FormHelperText, InputBase, InputLabel, Tooltip } from '@mui/material';

const ChipsTextInput = forwardRef(
    (
        {
            value,
            label = null,
            type = 'text',
            error,
            helperText,
            endAdornment = null,
            tooltipText = null,
            multiline = false,
            sx = {},

            ...other
        },
        ref,
    ) => {
        const [chips, setChips] = useState(value || []);
        const [inputValue, setInputValue] = useState('');

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && inputValue.trim() !== '') {
                setChips([...chips, inputValue.trim()]);
                setInputValue('');
                event.preventDefault();
                other.onChange([...chips, inputValue.trim()]);
            }
        };

        const handleDelete = (chipToDelete) => () => {
            setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
            other.onChange(chips.filter((chip) => chip !== chipToDelete));
        };

        return (
            <FormControl variant="outlined" fullWidth error={error}>
                {label && (
                    <InputLabel
                        shrink
                        htmlFor="chips-input"
                        sx={{
                            marginTop: '10px',
                            color: '#5E6366',
                            pl: 1,
                            '&.Mui-focused': {
                                color: error ? (theme) => theme.palette.error.main : '#5E6366 !important',
                            },
                        }}
                    >
                        {label}
                    </InputLabel>
                )}
                <Tooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
                    <InputBase
                        id="chips-input"
                        type={type}
                        value={inputValue}
                        onKeyDown={handleKeyDown}
                        startAdornment={
                            <Box display="flex" flexWrap="wrap" gap={1}>
                                {chips.map((chip, index) => (
                                    <Chip
                                        key={index}
                                        label={chip}
                                        onDelete={handleDelete(chip)}
                                        sx={{ margin: '2px' }}
                                    />
                                ))}
                            </Box>
                        }
                        endAdornment={endAdornment}
                        sx={[
                            error && {
                                boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                                borderColor: (theme) => theme.palette.error.main,
                                borderRadius: '8px',
                            },
                            {
                                position: 'relative',
                                border: '1px solid #5E6366',
                                borderRadius: '8px',
                                backgroundColor: 'white',
                                fontSize: 16,
                                width: '100%',
                                padding: '5px 12px',
                                marginTop: '25px',
                            },
                            sx,
                        ]}
                        multiline={multiline}
                        inputProps={
                            multiline
                                ? {
                                      style: {
                                          marginTop: -6,
                                      },
                                  }
                                : {}
                        }
                        {...other}
                        onChange={(e) => setInputValue(e.target.value)}
                        ref={ref}
                    />
                </Tooltip>
                <Box display={'flex'} flexDirection={'row'} mb={2}>
                    <FormHelperText sx={{ width: '75%', color: '#8B8D97!important' }}>
                        {helperText ? helperText : ' '}
                    </FormHelperText>
                    <FormHelperText sx={{ pl: 1, width: '25%', textAlign: 'end' }}>
                        {other?.inputProps?.maxLength ? `${value?.length || 0} / ${other?.inputProps.maxLength}` : ''}
                    </FormHelperText>
                </Box>
            </FormControl>
        );
    },
);

export default ChipsTextInput;
