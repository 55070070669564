import React, { useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { endOfDay } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { trans } from 'matice';
import { DatePickerInput, SelectInput, TextInput } from '~js/components/input';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import { SURVEY_TYPOLOGIES } from '~js/constants/survey.js';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';
import NewSurveyActionButtons from './NewSurveyActionButtons';
import SurveyContext from '../context';

const typologyOptions = [
    { _id: 1, label: trans('survey.linear'), value: SURVEY_TYPOLOGIES.linear },
    { _id: 2, label: trans('survey.multiFlow'), value: SURVEY_TYPOLOGIES.multiFlow },
];

const NewSurveyTypologyStep = ({
    handleStepNext,
    handleStepPrevious,
    stepFormValue,
    isLoading = false,
    touchpoints,
}) => {
    const { isEditMode, currentClientSurveyStatus, formDisabled } = useContext(SurveyContext);

    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setValue,
    } = useForm({
        defaultValues: stepFormValue
            ? stepFormValue
            : {
                  title: '',
                  description: '',
                  typology: '',
                  startDate: '',
                  endDate: '',
              },
    });

    const onSubmitHandler = async (data) => {
        handleStepNext({ general: data });
    };

    const touchpointSelectOptions = touchpoints.map((touchpoint) => ({
        label: `${touchpoint.name} (${touchpoint.type})`,
        value: touchpoint._id,
    }));

    const selectedTouchpoint = touchpoints.find((touchpoint) => touchpoint._id === watch('touchpoint'));

    const selectedTouchpointPathOptions = selectedTouchpoint?.urls?.map((path) => ({
        label: `${selectedTouchpoint?.domain}${path}`,
        value: path,
    }));

    return (
        <Box mt={4}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box maxWidth="500px">
                            {touchpointSelectOptions?.length > 0 && (
                                <Controller
                                    control={control}
                                    name="touchpoint"
                                    shouldUnregister
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <SelectInput
                                            disabled={isEditMode}
                                            label={trans('survey.touchpoint')}
                                            selectOptions={touchpointSelectOptions}
                                            helperText={errors?.touchpoint?.message}
                                            error={!!errors?.touchpoint}
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                field.onBlur();
                                                const selectedTouchpoint = touchpoints.find(
                                                    (touchpoint) => touchpoint._id === e.target.value,
                                                );
                                                if (selectedTouchpoint?.type === 'web') {
                                                    setValue('typology', SURVEY_TYPOLOGIES.linear);
                                                }
                                            }}
                                        />
                                    )}
                                />
                            )}

                            <Box sx={{ mt: '30px' }}>
                                <Controller
                                    control={control}
                                    name="typology"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <SelectInput
                                            disabled={isEditMode || selectedTouchpoint?.type === 'web'}
                                            label={trans('survey.typology')}
                                            selectOptions={typologyOptions}
                                            helperText={errors?.typology?.message}
                                            error={!!errors?.typology}
                                            {...field}
                                        />
                                    )}
                                />
                            </Box>

                            {selectedTouchpoint?.type === 'web' && (
                                <Box sx={{ mt: '30px' }}>
                                    <Controller
                                        control={control}
                                        name="path"
                                        shouldUnregister
                                        rules={{
                                            required: trans('formValidation.requiredField'),
                                        }}
                                        render={({ field }) => (
                                            <SelectInput
                                                disabled={
                                                    formDisabled ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodActive
                                                }
                                                label={trans('survey.path')}
                                                selectOptions={selectedTouchpointPathOptions}
                                                helperText={errors?.path?.message}
                                                error={!!errors?.path}
                                                {...field}
                                            />
                                        )}
                                    />
                                </Box>
                            )}

                            <Box sx={{ display: 'flex', mt: '30px' }}>
                                <Box sx={{ width: '100%', mr: 4 }}>
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        rules={
                                            formDisabled || currentClientSurveyStatus === clientSurveyStatus.prodActive
                                                ? {}
                                                : {
                                                      required: trans('formValidation.requiredField'),
                                                      validate: (val) =>
                                                          val > new Date() || trans('survey.selectDateFuture'),
                                                      deps: ['endDate'],
                                                  }
                                        }
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerInput
                                                disabled={
                                                    formDisabled ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodActive
                                                }
                                                label={trans('survey.dateStart')}
                                                value={value}
                                                onChangeValue={onChange}
                                                helperText={errors?.startDate?.message}
                                                error={!!errors?.startDate}
                                            />
                                        )}
                                    />
                                </Box>
                                <Controller
                                    control={control}
                                    name="endDate"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                        validate: (val) =>
                                            val > watch('startDate') || trans('survey.endMustBeGreaterThanStart'),
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerInput
                                            disabled={formDisabled}
                                            label={trans('survey.dateEnd')}
                                            value={value}
                                            onChangeValue={(e) => {
                                                onChange(endOfDay(e));
                                            }}
                                            helperText={errors?.endDate?.message}
                                            error={!!errors?.endDate}
                                            timeEnabled={false}
                                            minDateTime={watch('startDate')}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ maxWidth: '500px' }}>
                            <Controller
                                control={control}
                                name="title"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                    minLength: {
                                        value: 3,
                                        message: trans('formValidation.fieldTooShort'),
                                    },
                                    maxLength: {
                                        value: 50,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisabled}
                                        label={trans('survey.title')}
                                        placeholder={trans('survey.title')}
                                        helperText={errors?.title?.message}
                                        error={!!errors?.title}
                                        {...field}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="description"
                                rules={{
                                    maxLength: {
                                        value: 1000,
                                        message: trans('formValidation.fieldTooLong'),
                                    },
                                    pattern: {
                                        value: REGEX_ONLY_PLAIN_CHAR,
                                        message: trans('formValidation.charNotAllowed'),
                                    },
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        multiline
                                        rows={4}
                                        disabled={formDisabled}
                                        label={trans('survey.description')}
                                        placeholder={trans('survey.description')}
                                        helperText={errors?.description?.message}
                                        error={!!errors?.description}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {!formDisabled && (
                    <NewSurveyActionButtons
                        isLoading={isLoading}
                        onLeftButtonClick={handleStepPrevious}
                        rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                        leftButtonText={trans('global.cancel')}
                    />
                )}
            </form>
        </Box>
    );
};

export default NewSurveyTypologyStep;
