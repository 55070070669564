import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Paper, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import axios from 'axios';
import { trans } from 'matice';
import AuthLayout from '~js/layouts/AuthLayout';
import TextInput from '~js/components/TextInput';
import CtaButton from '~js/components/CtaButton';
import toolsLogo from '~/assets/images/loyalty-built-logo.png';

const ForgotPasswordPage = () => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: {
            email: '',
        },
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const goToLoginPage = () => router.get(route('login'));

    const onSubmit = async (data) => {
        const { email } = data;
        try {
            setLoading(true);
            await axios.post(route('sendResetPasswordEmail'), { email });
            setSuccess(true);
        } catch (e) {
            toast.error(trans('auth.resetPassword.errorMail'));
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Head title={trans('auth.login.passwordLost')} />
            <AuthLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: 750,
                                p: 8,
                            }}
                        >
                            <Box component="img" src={toolsLogo} maxWidth="194px" alt={'Logo tools loyalty'} mb={3} />
                            <Typography variant="h6" mt={3} mb={6}>
                                {trans('auth.resetPassword.title')}
                            </Typography>

                            {!success ? (
                                <>
                                    <Box width="100%" maxWidth="400px">
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{
                                                required: trans('formValidation.requiredField'),
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: trans('formValidation.insertValidEmail'),
                                                },
                                            }}
                                            render={({ field }) => (
                                                <TextInput
                                                    id={'email'}
                                                    autoComplete={'email'}
                                                    label={trans('auth.views.profile.email')}
                                                    placeholder={trans('auth.views.profile.email-plc')}
                                                    helperText={errors?.email?.message}
                                                    error={!!errors?.email}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        <CtaButton
                                            disabled={loading}
                                            type="button"
                                            sx={{ px: 0, textDecoration: 'underline' }}
                                            onClick={goToLoginPage}
                                        >
                                            {trans('auth.resetPassword.returnToLogin')}
                                        </CtaButton>
                                    </Box>
                                    <CtaButton
                                        type="submit"
                                        variant="contained"
                                        sx={{ px: 6, my: 6 }}
                                        loading={loading}
                                    >
                                        {trans('global.continue')}
                                    </CtaButton>
                                </>
                            ) : (
                                <>
                                    <Typography>{trans('auth.resetPassword.messageMail')}</Typography>
                                    <Typography>{trans('auth.resetPassword.postMailSendInstructions')}</Typography>
                                </>
                            )}
                        </Paper>
                    </Box>
                </form>
            </AuthLayout>
        </>
    );
};

export default ForgotPasswordPage;
