export const TOUCHPOINT_FONTS_SELECT_OPTIONS = [
    { value: 'https://fonts.bunny.net/family/nunito', label: 'Nunito' },
    { value: 'https://fonts.bunny.net/family/roboto', label: 'Roboto' },
    { value: 'https://fonts.bunny.net/family/open-sans', label: 'Open-Sans' },
    { value: 'https://fonts.bunny.net/family/lato', label: 'Lato' },
    { value: 'https://fonts.bunny.net/family/montserrat', label: 'Montserrat' },
    { value: 'https://fonts.bunny.net/family/raleway', label: 'Raleway' },
    { value: 'https://fonts.bunny.net/family/grandstander', label: 'Grandstander' },
];

export const TOUCHPOINT_BORDER_RADIUS_SELECT_OPTIONS = [
    { value: 'squared', label: 'Squared (0px)' },
    { value: 'rounded', label: 'Rounded (24px)' },
    { value: 'circle', label: 'Circle (90px)' },
];
