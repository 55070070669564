import React, { forwardRef, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Tooltip from '@mui/material/Tooltip';

export const CustomTextInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: '8px',
        position: 'relative',
        backgroundColor: 'white',
        border: '1px solid #5E6366',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.5)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
            borderRadius: '8px',
        },
        '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
        },
    },
}));

const TextInput = forwardRef(
    (
        {
            value,
            label = null,
            type = 'text',
            error,
            helperText,
            endAdornment = null,
            tooltipText = null,
            multiline = false,
            sx = {},
            ...other
        },
        ref,
    ) => {
        // workaround for InputProps Error
        delete other?.InputProps;
        const [showPass, setShowPass] = useState(false);

        return (
            <Box width="100%">
                <FormControl variant="standard" fullWidth error={error}>
                    {label && (
                        <InputLabel
                            shrink
                            htmlFor="input"
                            sx={{
                                color: '#5E6366',
                                pl: 1,
                                '&.Mui-focused': {
                                    color: error ? (theme) => theme.palette.error.main : '#5E6366 !important',
                                },
                            }}
                        >
                            {label}
                        </InputLabel>
                    )}
                    <Tooltip title={tooltipText} placement="bottom-start" sx={{ zIndex: 99 }}>
                        <CustomTextInput
                            id="input"
                            type={showPass ? 'text' : type}
                            value={value}
                            sx={[
                                error && {
                                    boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                                    borderColor: (theme) => theme.palette.error.main,
                                    borderRadius: '8px',
                                },
                                type === 'password' && { '& .MuiInputBase-input': { pr: '35px' } },
                                sx,
                            ]}
                            multiline={multiline}
                            inputProps={
                                multiline
                                    ? {
                                          style: {
                                              marginTop: -6,
                                          },
                                      }
                                    : {}
                            }
                            {...other}
                            ref={ref}
                        />
                    </Tooltip>

                    {type === 'password' ? (
                        <InputAdornment
                            position="end"
                            sx={{ position: 'absolute', right: 10, bottom: helperText ? 44 : 22 }}
                        >
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPass((val) => !val)}
                                edge="end"
                            >
                                {showPass ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        endAdornment ?? <></>
                    )}
                </FormControl>
                <Box display={'flex'} flexDirection={'row'} mb={2}>
                    <FormHelperText sx={{ pl: 1, width: '75%' }}>{helperText ? helperText : ' '}</FormHelperText>
                    <FormHelperText sx={{ pl: 1, width: '25%', textAlign: 'end' }}>
                        {other?.inputProps?.maxLength ? `${value?.length || 0} / ${other?.inputProps.maxLength}` : ''}
                    </FormHelperText>
                </Box>
            </Box>
        );
    },
);

export default TextInput;
