import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Head, router, usePage } from '@inertiajs/react';
import { trans } from 'matice';
import { toast } from 'react-toastify';
import CtaButton from '~js/components/CtaButton';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import EmptySection from '~js/components/EmptySection';
import SurveyTable from './components/SurveyTable';
import FilterMenuTable, { SURVEY_TYPOLOGIES_FILTER_OPTIONS } from './components/FilterMenuTable';
import AppliedFilterSection from './components/AppliedFilterSection';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';
import { parseQueryParamsArray } from '~js/utils/queryParams';
import ModalTestSurveyWeb from './components/ModalTestSurveyWeb';

const breadcrumbs: BreadcrumbList = [
    {
        routeName: 'Surveys.index',
        routeLabel: 'Surveys',
    },
];

const SurveyIndex = ({ surveys, testerGroups, can, canReadToolIds, brand, ...other }) => {
    console.log('SurveyIndex Props', { surveys, testerGroups, can, brand, ...other });
    const [appliedFilters, setAppliedFilters] = useState();
    const [showModalTestSurveyWeb, setShowModalTestSurveyWeb] = useState(null); // null or idSurvey

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const showArchived = params.get('showArchived');
        const startDate = params.get('startDate');
        const endDate = params.get('endDate');
        const fromCoverage = params.get('fromCoverage');
        const toCoverage = params.get('toCoverage');

        const arrayQueryParams = parseQueryParamsArray(params);
        if (
            !!showArchived ||
            !!startDate ||
            !!endDate ||
            !!fromCoverage ||
            !!toCoverage ||
            Object.keys(arrayQueryParams).length > 0
        ) {
            const arrayFilters = {};
            if (arrayQueryParams?.typologies) {
                arrayFilters['typologies'] = SURVEY_TYPOLOGIES_FILTER_OPTIONS.filter((option) =>
                    arrayQueryParams.typologies.includes(option.value),
                );
            }
            setAppliedFilters({
                showArchived,
                startDate,
                endDate,
                fromCoverage,
                toCoverage,
                ...arrayFilters,
            });
        } else {
            setAppliedFilters(null);
        }
    }, []);

    const goToCreateSurveyPage = () => router.get(route('Surveys.create', { brand }));

    const deleteSurvey = async (idSurvey) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Surveys.destroy', idSurvey), {
                onSuccess: () => {
                    toast.success(trans('survey.surveyDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('survey.errorOnDeleteSurvey'));
                },
            }),
        );
        await promise;
    };

    const changeSurveyStatus = async (idSurvey, newStatus, groupTest = []) => {
        console.log(idSurvey, newStatus);
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route('Surveys.changeStatus', {
                    id: idSurvey,
                    status: newStatus,
                }),
                groupTest.length > 0 ? { groupTest } : {},
                {
                    onSuccess: () => {
                        toast.success(trans('survey.statusChanged'));
                        resolve();
                    },
                    onError: (e) => {
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('survey.errorOnChangeStatus'));
                    },
                },
            ),
        );
        await promise;
    };

    const filterSurvey = async (queryObj) => {
        console.log('queryObj', queryObj);
        if (!queryObj.showArchived) {
            delete queryObj.showArchived;
        }
        const promise = new Promise((resolve, reject) =>
            router.get(
                route('Surveys.index', queryObj),
                {},
                {
                    onSuccess: () => {
                        toast.success(trans('survey.surveyFetchedSuccess'));
                        resolve();
                    },
                    onError: (e) => {
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('survey.surveyFetchedError'));
                    },
                },
            ),
        );

        await promise;
    };

    return (
        <>
            <Head title={trans('survey.survey')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <FilterMenuTable
                        buttonFocused={!!appliedFilters}
                        onSubmit={filterSurvey}
                        appliedFilters={appliedFilters}
                        typology={'survey'}
                    />

                    {surveys.length > 0 && can.create && (
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                                ml: 2,
                            }}
                            onClick={goToCreateSurveyPage}
                        >
                            {trans('survey.createNew')}
                        </CtaButton>
                    )}
                </Box>
                {!!appliedFilters && (
                    <AppliedFilterSection appliedFilters={appliedFilters} onSubmit={filterSurvey} typology={'survey'} />
                )}
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
                    <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                        {trans('survey.survey')}
                    </Typography>
                </Box>
                <SurveyTable
                    brand={brand}
                    surveys={surveys}
                    testerGroups={testerGroups}
                    deleteSurvey={deleteSurvey}
                    changeSurveyStatus={changeSurveyStatus}
                    canReadToolIds={canReadToolIds}
                    canUpdate={can.updateAny}
                    canDelete={can.deleteAny}
                    canArchive={can.archiveAny}
                    canDuplicate={can.create}
                    onTestSurveyWebClick={(idSurvey) => setShowModalTestSurveyWeb(idSurvey)}
                />
                {surveys.length === 0 && (
                    <EmptySection
                        title={trans('survey.noSurveyTitle')}
                        description={
                            appliedFilters
                                ? trans('survey.noSurveyFoundAfterFilter')
                                : trans('survey.noSurveyDescription')
                        }
                        buttonComponent={
                            <CtaButton
                                variant="contained"
                                sx={{
                                    px: 5,
                                }}
                                onClick={goToCreateSurveyPage}
                            >
                                {trans('survey.createNew')}
                            </CtaButton>
                        }
                    />
                )}
            </ToolContentPageLayout>
            {!!showModalTestSurveyWeb && (
                <ModalTestSurveyWeb
                    show
                    onClose={() => setShowModalTestSurveyWeb(null)}
                    surveyId={showModalTestSurveyWeb}
                />
            )}
        </>
    );
};

export default SurveyIndex;
