import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { trans } from 'matice';
import NewSurveyActionButtons from './NewSurveyActionButtons';
import ComposeClusters from './ComposeClusters';
import ExposureForm from './ExposureForm';
import SelectTestGroupForm from './SelectTestGroupForm';
import SurveyContext from '../context';
import CoverageExclusionsForm from './CoverageExclusionsForm';

const NewSurveyAdministrationStep = ({
    handleStepNext,
    handleStepPrevious,
    clusters,
    events,
    isLoading,
    stepFormValue,
    testerGroups,
    surveys = [],
}) => {
    const { isEditMode, formDisabled, touchpointType } = useContext(SurveyContext);
    const [audienceTested, setAudienceTested] = useState(false);

    const formMethods = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: stepFormValue
            ? stepFormValue
            : {
                  clustersGroups: [
                      {
                          operatorWithPrevious: 'AND',
                          operatorInsideGroup: 'AND',
                          clusters: [' '],
                      },
                  ],
                  isPeriodic: false,
                  realTimeActivation: false,
                  groupTest: [],
              },
    });

    const { handleSubmit, setError } = formMethods;

    const onSubmit = (data) => {
        if ((touchpointType === 'api' || formMethods.watch('audienceType') === 'authenticated') && !audienceTested) {
            setError('checkAudience', { type: 'custom', message: trans('survey.checkAudienceRequired') });
            return;
        }
        handleStepNext({ administration: data });
    };

    return (
        <FormProvider {...formMethods}>
            <Box component="form" width="100%" onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={6}>
                    <ComposeClusters clusters={clusters} />
                    {(touchpointType === 'api' || formMethods.watch('audienceType') === 'authenticated') && (
                        <>
                            <CoverageExclusionsForm surveys={surveys} setAudienceTested={setAudienceTested} />
                            <ExposureForm events={events} />
                            <SelectTestGroupForm testerGroups={testerGroups} />
                        </>
                    )}
                    <Box width="100%">
                        {!formDisabled && (
                            <NewSurveyActionButtons
                                isLoading={isLoading}
                                onLeftButtonClick={handleStepPrevious}
                                rightButtonText={isEditMode ? trans('global.save') : trans('global.next')}
                                leftButtonText={trans('global.back')}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </FormProvider>
    );
};

export default NewSurveyAdministrationStep;
