import React, { useMemo } from 'react';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
} from '@mui/material';
import { Link, usePage } from '@inertiajs/react';
import SpeedDialSuperAdmin from '~js/components/SpeedDialSuperAdmin';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { trans } from 'matice';
import toolsLogo from '~/assets/images/loyalty-built-logo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import getBrandsOptions from '~/js/utils/getBrandsOptions';
import PhonelinkIcon from '@mui/icons-material/Phonelink';

const drawerWidth = 250;

const ToolNavBarLayout = ({ children }) => {
    const {
        url,
        props: { tabMenuCanView, isSuperAdmin, brand, userBrandsWithRoles },
    } = usePage();

    const [selectBrandAnchorEl, setSelectBrandAnchorEl] = React.useState(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuSelectBrandClick = (index) => {
        const selectedBrand = brandsOptions[index].value;
        // quick fix to redirect to the surveys page with the selected brand to avoid error with navigation withouth brand
        window.location.href = route('Surveys.index', { brand: selectedBrand });
        // router.get(route('Tools.index', { brand: brandsOptions[index].value }));
    };

    const brandsOptions = getBrandsOptions(userBrandsWithRoles);
    const currentBrandOptions = userBrandsWithRoles.find(({identifier}) => identifier === brand).settings;

    const menuOptionsToShow = useMemo(() => {
        return [
            {
                id: 'surveys',
                name: trans('navBarSurvey.survey'),
                path: route('Surveys.index', { brand }, false),
                renderIcon: () => <BallotOutlinedIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'quizzes',
                name: trans('navBarSurvey.quiz'),
                path: route('Quiz.index', { brand }, false),
                renderIcon: () => <QuestionMarkIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'clusters',
                name: trans('navBarSurvey.cluster'),
                path: route('Cluster.index', { brand }, false),
                renderIcon: () => <PeopleAltOutlinedIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'triggers',
                name: trans('navBarSurvey.triggers'),
                path: route('Triggers.index', { brand }, false),
                renderIcon: () => <NotificationsNoneOutlinedIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'events',
                name: trans('navBarSurvey.events'),
                path: route('Events.index', { brand }, false),
                renderIcon: () => <CalendarTodayOutlinedIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'touchpoints',
                name: trans('navBarSurvey.touchpoints'),
                path: route('Touchpoint.index', { brand }, false),
                renderIcon: () => <PhonelinkIcon sx={{ color: 'inherit' }} />,
            },
            {
                id: 'settings',
                name: trans('navBarSurvey.settings'),
                path: route('Settings.index', { brand }, false),
                renderIcon: () => <SettingsOutlinedIcon sx={{ color: 'inherit' }} />,
            },
        ].filter((menuOption) => tabMenuCanView.includes(menuOption.id));
    }, [brand]);

    const drawer = (
        <Box
            sx={{
                minHeight: '100vh',
                pt: '64px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    color: 'white',
                    pt: '16px',
                    borderWidth: 0,
                    minHeight: '100%',
                }}
            >
                <Box sx={{ px: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            m: '16px',
                            alignItems: 'center',
                            cursor: brandsOptions?.length > 1 ? 'pointer' : 'default',
                        }}
                        onClick={
                            brandsOptions?.length > 1 ? (e) => setSelectBrandAnchorEl(e.currentTarget) : () => null
                        }
                    >
                        <img src={currentBrandOptions?.logos?.main ?? toolsLogo} alt="Brand logo" width="85%" />
                        {brandsOptions?.length > 1 && (
                            <>
                                {!selectBrandAnchorEl && <ExpandMoreIcon sx={{ color: 'black' }} />}
                                {!!selectBrandAnchorEl && <ExpandLessIcon sx={{ color: 'black' }} />}
                            </>
                        )}
                    </Box>
                    <Menu
                        id="lock-menu"
                        anchorEl={selectBrandAnchorEl}
                        open={!!selectBrandAnchorEl}
                        onClose={() => setSelectBrandAnchorEl(null)}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        {brandsOptions.map((option, index) => (
                            <MenuItem
                                key={option.label}
                                // disabled={index === 0}
                                //  selected={index === selectedIndex}

                                onClick={() => handleMenuSelectBrandClick(index)}
                            >
                                <Box
                                    width={40}
                                    height={40}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    border={1}
                                    borderRadius="6px"
                                    marginRight="10px"
                                    borderColor="rgba(0, 0, 0, 0.1)"
                                >
                                    <Box component="img" src={option.image} maxWidth={30}></Box>
                                </Box>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Menu>
                    <List sx={{ pt: 2 }}>
                        {menuOptionsToShow.map((op) => (
                            <Box key={op.id}>
                                <Link
                                    href={op.path}
                                    headers={{ 'X-Inertia-Link': true }}
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            background: (theme) =>
                                                url.startsWith(op.path) ? theme.palette.primary.main : 'white',
                                            color: (theme) =>
                                                url.startsWith(`${op.path}`) ? 'white' : theme.palette.text.primary,
                                            /*   &:hover': {
                                                   color: url.startsWith(op.path) ? '#2d62ed' : 'white',
                                                   background: url.startsWith(op.path) && 'white',
                                               },,*/
                                            py: 1,
                                            borderRadius: 1,
                                            boxShadow: 'inherit',
                                        }}
                                    >
                                        <ListItemIcon sx={{ color: 'inherit' }}>{op.renderIcon()}</ListItemIcon>
                                        <ListItemText primary={op.name} />
                                    </ListItem>
                                </Link>
                            </Box>
                        ))}
                    </List>
                </Box>
            </Box>
        </Box>
    );

    const container = () => window.document.body;

    return (
        <Box
            sx={{
                display: 'flex',
                bgcolor: (theme) => theme.palette.background.default,
            }}
        >
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    bgcolor: (theme) => theme.palette.primary.main,
                    // width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {
                        sm: `${drawerWidth}px`,
                        md: `${drawerWidth}px`,
                        lg: `${drawerWidth}px`,
                        xl: `${drawerWidth}px`,
                    },
                    zIndex: 999,
                }}
                elevation={0}
            >
                <Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <div>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{
                                    mr: 2,
                                    display: { sm: 'none' },
                                    color: 'white',
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        {/* <IconButton
                            sx={{
                                backgroundColor: (theme) => theme.palette.primary.light,
                                p: 0,
                                width: '50px',
                                height: '50px',
                            }}
                        >
                            <Link href={'/logout'} style={{ color: 'white', padding: 0 }}>
                                <LogoutIcon />
                            </Link>
                        </IconButton>*/}
                    </Box>
                </Toolbar>
                {isSuperAdmin && <SpeedDialSuperAdmin />}
            </AppBar>
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                    // minHeight: '100vh',
                }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        minHeight: '100vh',
                        display: {
                            xs: 'block',
                            sm: 'none',
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        //minHeight: '100vh',
                        display: {
                            xs: 'none',
                            sm: 'block',
                        },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            //borderRightWidth: 0,
                            overflowX: 'hidden',
                            zIndex: 99,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    //px: 2,
                    width: {
                        sm: `calc(100% - ${drawerWidth}px)`,
                    },
                }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
};

export default ToolNavBarLayout;
