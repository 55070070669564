import React from 'react';
import SurveyCreate from './SurveyCreate';

const SurveyDuplicate = ({ clusters, events, testerGroups, surveys, surveysToDuplicate, touchpoints = [] }) => {
    console.log('SurveyDuplicate props', { clusters, events, testerGroups, surveys, surveysToDuplicate });

    const clusterGroupAdministrationStep = surveysToDuplicate.audience.definition.map((cg) => {
        const operatorWithPrevious = Object.keys(cg)[0];
        const operatorInsideGroup = Object.keys(cg[operatorWithPrevious])[0];
        const clusters = cg[operatorWithPrevious][operatorInsideGroup];
        return { operatorWithPrevious, operatorInsideGroup, clusters };
    });

    const defaultValue = {
        general: {
            title: surveysToDuplicate?.general?.title,
            description: surveysToDuplicate?.general?.description,
            typology: surveysToDuplicate?.general?.typology,
            startDate: new Date(surveysToDuplicate?.general?.startDate),
            endDate: new Date(surveysToDuplicate?.general?.endDate),
        },
        questions: {
            randomizeResponses: surveysToDuplicate?.general?.randomizeResponses
                ? surveysToDuplicate?.general?.randomizeResponses
                : false,
            questions: surveysToDuplicate?.questions,
        },
        introduction: surveysToDuplicate?.introduction,
        closure: {
            ...surveysToDuplicate?.closure,
            points: surveysToDuplicate?.introduction?.points,
        },
        administration: {
            clustersGroups: clusterGroupAdministrationStep,
            event: surveysToDuplicate?.general?.event || '',
            isEventSurvey: !!surveysToDuplicate?.general?.event,
            isPeriodic: !!surveysToDuplicate?.general?.isPeriodic,
            realTimeActivation: surveysToDuplicate?.general?.realTimeActivation,
            groupTest: surveysToDuplicate?.general?.groupTest || [],
            intervalDays: surveysToDuplicate?.general?.intervalDays,
            maxExposureNumber: surveysToDuplicate?.general?.maxExposureNumber,
            shouldExpireProgrammatically: !!surveysToDuplicate?.general?.programmaticDeadlineDays,
            programmaticDeadlineDays: surveysToDuplicate?.general?.programmaticDeadlineDays,
            withExclusions: !!surveysToDuplicate?.audience?.selectedAudience,
            selectedAudience: surveysToDuplicate?.audience?.selectedAudience || '',
            surveysToExclude: surveysToDuplicate?.audience?.surveysToExclude || [],
        },
        communication: {
            isEmailActive: surveysToDuplicate?.general?.isEmailActive,
            isNotificationActive: surveysToDuplicate?.general?.isNotificationActive,
        },
    };
    return (
        <SurveyCreate
            touchpoints={touchpoints}
            surveys={surveys}
            testerGroups={testerGroups}
            clusters={clusters}
            events={events}
            defaultValueDuplicateSurvey={defaultValue}
        />
    );
};

export default SurveyDuplicate;
