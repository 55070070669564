import React, { useEffect, useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { isFuture } from 'date-fns';
import { toast } from 'react-toastify';
import { trans } from 'matice';
import ToolContentPageLayout from '~js/layouts/Tools/ToolContentPageLayout';
import CtaButton from '~js/components/CtaButton';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import useConfirmReload from '~js/hooks/useConfirmReload';
import NewSurveyTypologyStep from './components/NewSurveyTypologyStep';
import NewSurveyQuestionsStep from './components/NewSurveyQuestionsStep';
import NewSurveyIntroductionClosureStep from './components/NewSurveyIntroductionClosureStep';
import NewSurveyAdministrationStep from './components/NewSurveyAdministrationStep';
import NewSurveyCommunicationsStep from './components/NewSurveyCommunicationsStep';
import SurveyStatusSelectInput from '~js/pages/backoffice/Tools/Survey/components/SurveyStatusSelectInput';
import SurveyContext from './context';
import ModalTestSurveyWeb from './components/ModalTestSurveyWeb';
import type { BreadcrumbList } from '~js/components/Breadcrumbs';

const changeOrientationBreakPoint = 1100;

const SurveyEdit = ({ survey, clusters, events, testerGroups, can, surveys, touchpoints = [] }) => {
    console.log(survey);
    useConfirmReload();

    const [tabValue, setTabValue] = useState(0);
    const [tabsOrientation, setTabsOrientation] = useState(
        window.innerWidth < changeOrientationBreakPoint ? 'horizontal' : 'vertical',
    );
    const [isLoading, setIsLoading] = useState(false);
    const [showModalTestSurveyWeb, setShowModalTestSurveyWeb] = useState(false);

    const editDisabled =
        survey?.general?.status === clientSurveyStatus.expired ||
        survey?.general?.status === clientSurveyStatus.revoked;

    let currentClientSurveyStatus = survey?.general?.status;

    if (currentClientSurveyStatus === 'prod') {
        currentClientSurveyStatus = isFuture(new Date(survey?.general?.startDate))
            ? clientSurveyStatus.prodNotActive
            : clientSurveyStatus.prodActive;
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < changeOrientationBreakPoint) {
                setTabsOrientation('horizontal');
            }
            if (window.innerWidth >= changeOrientationBreakPoint) {
                setTabsOrientation('vertical');
            }
        });
        return () => window.removeEventListener('resize', () => {});
    }, []);

    const changeSurveyStatusHandler = async (idSurvey, newStatus, groupTest = []) => {
        const promise = new Promise((resolve, reject) =>
            router.patch(
                route('Surveys.changeStatus', { id: idSurvey, status: newStatus }),
                { groupTest },
                {
                    onStart: () => setIsLoading(true),
                    onFinish: () => setIsLoading(false),
                    onSuccess: () => {
                        toast.success(trans('survey.statusChanged'));
                        resolve();
                    },
                    onError: (e) => {
                        reject();
                        toast.error(!!e?.generic ? e.generic : trans('survey.errorOnChangeStatus'));
                    },
                },
            ),
        );
        await promise;
    };

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };
    const goToSurveyList = () => router.get(route('Surveys.index'));

    const clusterGroupAdministrationStep = survey.audience.definition.map((cg) => {
        const operatorWithPrevious = Object.keys(cg)[0];
        const operatorInsideGroup = Object.keys(cg[operatorWithPrevious])[0];
        const clusters = cg[operatorWithPrevious][operatorInsideGroup];
        return { operatorWithPrevious, operatorInsideGroup, clusters };
    });

    const onSavePress = (newData, step) => {
        console.log('newData', newData);
        let dataToSubmit = { ...survey };
        switch (step) {
            case 'typology':
                dataToSubmit['general'] = {
                    ...survey.general,
                    ...newData.general,
                };
                break;
            case 'questions':
                dataToSubmit['questions'] = newData.questions.questions;
                dataToSubmit['general'] = {
                    ...survey.general,
                    randomizeResponses: newData.questions.randomizeResponses,
                };
                break;
            case 'introduction':
                dataToSubmit['introduction'] = newData.introduction;
                break;
            case 'closure':
                dataToSubmit['closure'] = newData.closure;
                break;
            case 'administration':
                dataToSubmit['audience'] = {
                    definition: newData.administration.clustersGroups.map((cg) => ({
                        [cg.operatorWithPrevious]: {
                            [cg.operatorInsideGroup]: cg.clusters,
                        },
                    })),
                    surveysToExclude: newData.administration.surveysToExclude || [],
                    selectedAudience: newData.administration.selectedAudience || null,
                };
                delete newData.administration.clustersGroups;
                delete newData.administration.surveysToExclude;
                delete newData.administration.selectedAudience;
                dataToSubmit['general'] = {
                    ...survey.general,
                    ...newData.administration,
                    event: !!newData.administration.isEventSurvey ? newData.administration.event : null,
                    intervalDays: !!newData.administration.isPeriodic ? newData.administration.intervalDays : null,
                    maxExposureNumber: !!newData.administration.isPeriodic
                        ? newData.administration.maxExposureNumber
                        : null,
                    programmaticDeadlineDays: !!newData.administration.shouldExpireProgrammatically
                        ? newData.administration.programmaticDeadlineDays
                        : null,
                };
                break;
            case 'communications':
                dataToSubmit['general'] = {
                    ...survey.general,
                    ...newData.communication,
                };
                break;
        }

        console.log('dataToSubmit', dataToSubmit);

        router.post(
            route('Surveys.update', survey._id),
            { _method: 'patch', ...dataToSubmit },
            {
                onStart: () => setIsLoading(true),
                onFinish: () => setIsLoading(false),
                onSuccess: () => toast.success(trans('survey.surveyEdited')),
                onError: (e) => {
                    toast.error(!!e?.generic ? e.generic : trans('survey.errorOnDeleteSurvey'));
                },
            },
        );
    };

    const selectedTouchpoint = touchpoints.find((touchpoint) => touchpoint._id === survey?.general?.touchpoint);

    const breadcrumbs: BreadcrumbList = [
        {
            routeName: 'Surveys.edit',
            routeLabel: 'Modifica Survey',
            params: {
                id: survey._id,
            },
        },
        {
            routeName: 'Surveys.index',
            routeLabel: 'Surveys',
        },
    ];
    return (
        <>
            <Head title={trans('survey.summary')} />

            <SurveyContext.Provider
                value={{
                    isEditMode: true,
                    currentClientSurveyStatus,
                    formDisabled: editDisabled || !can.update,
                    surveyTypology: survey?.general.typology,
                    surveyTitle: survey?.general?.title,
                    surveyStartDate: survey?.general?.startDate,
                    surveyEndDate: survey?.general?.endDate,
                    touchpointType: selectedTouchpoint?.type || 'api',
                }}
            >
                <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                    <Box display="flex" flexDirection={tabsOrientation === 'vertical' ? 'row' : 'column'}>
                        <Box
                            sx={{
                                borderRight: tabsOrientation === 'vertical' ? '1px solid #E1E2E9' : '',
                            }}
                            p={2}
                        >
                            <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={4}>
                                <Box>
                                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                        {trans('survey.summary')}
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <SurveyStatusSelectInput
                                            disabled={!can.update}
                                            label={trans('survey.status')}
                                            surveyStartDate={survey?.general?.startDate}
                                            surveyEndDate={survey?.general?.endDate}
                                            value={survey?.general?.status}
                                            onChange={(newStatus, testGroups) =>
                                                changeSurveyStatusHandler(survey._id, newStatus, testGroups)
                                            }
                                            testerGroups={testerGroups}
                                            selectedGroupsTest={survey?.general?.groupTest}
                                            touchpointType={selectedTouchpoint?.type || 'api'}
                                        />
                                    </Box>
                                </Box>
                                {tabsOrientation === 'horizontal' && (
                                    <>
                                        <CtaButton
                                            variant="outlined"
                                            sx={{ px: 4 }}
                                            onClick={goToSurveyList}
                                            disabled={isLoading}
                                        >
                                            {trans('global.close')}
                                        </CtaButton>
                                        {survey?.general?.status === clientSurveyStatus.test && (
                                            <CtaButton
                                                variant="contained"
                                                sx={{ px: 4 }}
                                                onClick={() => setShowModalTestSurveyWeb(true)}
                                                disabled={isLoading}
                                            >
                                                {trans('survey.testSurvey')}
                                            </CtaButton>
                                        )}
                                    </>
                                )}
                            </Box>
                            <Tabs
                                textColor="inherit"
                                orientation={tabsOrientation}
                                variant="scrollable"
                                value={tabValue}
                                onChange={handleChangeTab}
                                TabIndicatorProps={{
                                    style: {
                                        background: 'none',
                                    },
                                }}
                                sx={{
                                    '& .Mui-selected': {
                                        bgcolor: (theme) => theme.palette.primary.main,
                                        borderRadius: 1,
                                        color: 'white',
                                    },
                                }}
                            >
                                <Tab label={trans('survey.typology')} sx={{ textTransform: 'none' }} />
                                <Tab label={trans('survey.questions')} sx={{ textTransform: 'none' }} />
                                <Tab label={trans('survey.introduction')} sx={{ textTransform: 'none' }} />
                                <Tab label={trans('survey.closure')} sx={{ textTransform: 'none' }} />
                                <Tab label={trans('survey.administration')} sx={{ textTransform: 'none' }} />
                                {selectedTouchpoint?.type === 'api' && (
                                    <Tab label={trans('survey.communications')} sx={{ textTransform: 'none' }} />
                                )}
                            </Tabs>
                        </Box>
                        <Box width="100%">
                            {tabsOrientation === 'vertical' && (
                                <Box display="flex" justifyContent="flex-end" width="100%" gap={2}>
                                    {survey?.general?.status === clientSurveyStatus.test && (
                                        <CtaButton
                                            variant="contained"
                                            sx={{ px: 4 }}
                                            onClick={() => setShowModalTestSurveyWeb(true)}
                                            disabled={isLoading}
                                        >
                                            {trans('survey.testSurvey')}
                                        </CtaButton>
                                    )}
                                    <CtaButton
                                        variant="outlined"
                                        sx={{ px: 6 }}
                                        onClick={goToSurveyList}
                                        disabled={isLoading}
                                    >
                                        {trans('global.close')}
                                    </CtaButton>
                                </Box>
                            )}
                            <Box sx={{ pl: 4, position: 'relative' }}>
                                {tabValue === 0 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.typology')}
                                        </Typography>
                                        <NewSurveyTypologyStep
                                            touchpoints={touchpoints}
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'typology')}
                                            stepFormValue={{
                                                touchpoint: survey?.general?.touchpoint,
                                                path: survey?.general?.path,
                                                title: survey?.general?.title,
                                                description: survey?.general?.description,
                                                typology: survey?.general?.typology,
                                                startDate: new Date(survey?.general?.startDate),
                                                endDate: new Date(survey?.general?.endDate),
                                            }}
                                        />
                                    </>
                                )}
                                {tabValue === 1 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.questions')}
                                        </Typography>

                                        <NewSurveyQuestionsStep
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'questions')}
                                            stepFormValue={{
                                                randomizeResponses: survey?.general?.randomizeResponses
                                                    ? survey?.general?.randomizeResponses
                                                    : false,
                                                questions: survey?.questions,
                                            }}
                                        />
                                    </>
                                )}
                                {tabValue === 2 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.introduction')}
                                        </Typography>
                                        <NewSurveyIntroductionClosureStep
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'introduction')}
                                            mode="introduction"
                                            stepFormValue={survey?.introduction}
                                        />
                                    </>
                                )}
                                {tabValue === 3 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.closure')}
                                        </Typography>
                                        <NewSurveyIntroductionClosureStep
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'closure')}
                                            mode="closure"
                                            stepFormValue={{
                                                ...survey?.closure,
                                                points: survey?.introduction?.points,
                                            }}
                                        />
                                    </>
                                )}
                                {tabValue === 4 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.administration')}
                                        </Typography>
                                        <NewSurveyAdministrationStep
                                            isLoading={isLoading}
                                            handleStepNext={(data) => onSavePress(data, 'administration')}
                                            clusters={clusters}
                                            events={events}
                                            stepFormValue={{
                                                audienceType: survey?.general?.audienceType,
                                                exposureAnonymousPercentage:
                                                    survey?.general?.exposureAnonymousPercentage,
                                                clustersGroups: clusterGroupAdministrationStep,
                                                event: survey?.general?.event || '',
                                                isEventSurvey: !!survey?.general?.event,
                                                isPeriodic: !!survey?.general?.isPeriodic,
                                                realTimeActivation: survey?.general?.realTimeActivation,
                                                groupTest: survey?.general?.groupTest || [],
                                                intervalDays: survey?.general?.intervalDays,
                                                maxExposureNumber: survey?.general?.maxExposureNumber,
                                                shouldExpireProgrammatically:
                                                    !!survey?.general?.programmaticDeadlineDays,
                                                programmaticDeadlineDays: survey?.general?.programmaticDeadlineDays,
                                                withExclusions: !!survey?.audience?.selectedAudience,
                                                selectedAudience: survey?.audience?.selectedAudience || '',
                                                surveysToExclude: survey?.audience?.surveysToExclude || [],
                                            }}
                                            testerGroups={testerGroups}
                                            surveys={surveys}
                                        />
                                    </>
                                )}

                                {tabValue === 5 && (
                                    <>
                                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                                            {trans('survey.communications')}
                                        </Typography>
                                        <NewSurveyCommunicationsStep
                                            handleStepNext={(data) => onSavePress(data, 'communications')}
                                            // handleStepPrevious={handleStepPrevious}
                                            isLoading={isLoading}
                                            stepFormValue={{
                                                isEmailActive: survey?.general?.isEmailActive,
                                                isNotificationActive: survey?.general?.isNotificationActive,
                                            }}
                                        />
                                    </>
                                )}
                                {editDisabled && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            bottom: 0,
                                            right: 0,
                                            left: 0,
                                            zIndex: 9,
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </ToolContentPageLayout>
            </SurveyContext.Provider>
            {showModalTestSurveyWeb && (
                <ModalTestSurveyWeb show onClose={() => setShowModalTestSurveyWeb(false)} surveyId={survey._id} />
            )}
        </>
    );
};

export default SurveyEdit;
