import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { trans } from 'matice';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ToolContentPageLayout from '~/js/layouts/Tools/ToolContentPageLayout';
import TouchpointWebsiteForm from './components/TouchpointWebsiteForm';
import TouchpointApiForm from './components/TouchpointApiForm';
import CtaButton from '~/js/components/CtaButton';
import SuccessModal from '~/js/components/SuccessModal';

const breadcrumbs = [
    {
        routeName: 'Touchpoint.create',
        routeLabel: 'Modifica Touchpoint',
    },
    {
        routeName: 'Touchpoint.index',
        routeLabel: 'Touchpoints',
    },
];

const TouchpointEdit = ({ brand, touchpoint, ...props }) => {
    const formMethods = useForm();

    const { handleSubmit } = formMethods;

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const goToTouchpointListPage = () => router.get(route('Touchpoint.index', { brand }));

    const onSubmit = async (data) => {
        let dataToSubmit = null;

        if (touchpoint.type === 'web') {
            dataToSubmit = {
                name: data.name,
                domain: data.domain,
                urls: data.urls,
                font: data.font,
                radius: {
                    cta: data.radiusCta,
                    modal: data.radiusModal,
                    box: data.radiusBox,
                },
                palette: {
                    primary: data.primaryColor,
                    secondary: data.secondaryColor,
                    text: data.textColor,
                },
            };
        }

        if (touchpoint.type === 'api') {
            dataToSubmit = { name: data.name };
        }

        const promise = new Promise((resolve, reject) =>
            router.patch(route('Touchpoint.update', touchpoint._id), dataToSubmit, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: () => {
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        try {
            setLoading(true);
            await promise;
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <FormProvider {...formMethods}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Head title={trans('touchpoint.createNewTouchpoint')} />
                    <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                        <CtaButton variant="outlined" onClick={goToTouchpointListPage} sx={{ mb: 4 }}>
                            <ArrowBackIcon mr={1} />
                            {trans('touchpoint.backToTouchpointList')}
                        </CtaButton>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                            {touchpoint?.name} - {touchpoint?.type}
                        </Typography>

                        {touchpoint?.type === 'web' && (
                            <TouchpointWebsiteForm
                                onCancelPress={goToTouchpointListPage}
                                loading={loading}
                                touchpointToEdit={touchpoint}
                            />
                        )}
                        {touchpoint?.type === 'api' && (
                            <TouchpointApiForm
                                onCancelPress={goToTouchpointListPage}
                                loading={loading}
                                touchpointToEdit={touchpoint}
                            />
                        )}
                    </ToolContentPageLayout>
                </Box>
            </FormProvider>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('touchpoint.touchpointCreatedSuccess')}
                buttonText={trans('touchpoint.backToTouchpointList')}
                onPressButton={goToTouchpointListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default TouchpointEdit;
