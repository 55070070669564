import React, { useContext } from 'react';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { trans } from 'matice';
import ClusterGroup from './ClusterGroup';
import AndOrButton from './AndOrButton';
import SurveyContext from '../context';
import { clientSurveyStatus } from '~js/constants/missionStatuses';
import SelectInput from '~/js/components/SelectInput';
import TextInput from '~/js/components/TextInput';
import PercentIcon from '@mui/icons-material/Percent';

const audienceTypeSelectOptions = [
    { label: trans('cluster.anonymousUser'), value: 'anonymous' },
    { label: trans('cluster.authenticatedUser'), value: 'authenticated' },
];

const ComposeClusters = ({ clusters }) => {
    const { currentClientSurveyStatus, formDisabled, touchpointType } = useContext(SurveyContext);

    const clusterOptions = [
        {
            _id: 0,
            label: trans('survey.selectCluster'),
            value: ' ',
        },
        ...clusters?.map((c) => ({
            _id: c._id,
            label: c.title,
            value: c._id,
        })),
    ];

    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const {
        fields: clusterGroupFields,
        append: clusterGroupAppend,
        remove: clusterGroupRemove,
    } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: 'clustersGroups', // unique name for your Field Array
        // keyName: "id", default to "id", you can change the key name
    });

    const onChangeOperatorWithPrevious = (val) => {
        watch('clustersGroups').forEach((cg, index) => setValue(`clustersGroups.${index}.operatorWithPrevious`, val));
    };

    return (
        <Box
            width="100%"
            sx={{
                border: '1px solid #dfdfe4',
                borderRadius: 1,
                bgcolor: 'white',
            }}
        >
            <Box p={3}>
                <Typography width="100%" mb={1}>
                    {trans('survey.audience')}
                </Typography>

                {touchpointType === 'web' && (
                    <Box display="flex" gap={4}>
                        <Box flex={1} maxWidth="500px">
                            <Controller
                                control={control}
                                name="audienceType"
                                shouldUnregister
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                defaultValue="anonymous"
                                render={({ field }) => (
                                    <SelectInput
                                        disabled
                                        label={trans('cluster.audienceType')}
                                        selectOptions={audienceTypeSelectOptions}
                                        helperText={errors?.audienceType?.message}
                                        error={!!errors?.audienceType}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <Box flex={1} maxWidth="500px">
                            <Controller
                                control={control}
                                name="exposureAnonymousPercentage"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        disabled={formDisabled}
                                        label={trans('cluster.visualizationPercentage')}
                                        placeholder="es. 40"
                                        helperText={errors?.exposureAnonymousPercentage?.message}
                                        error={!!errors?.exposureAnonymousPercentage}
                                        sx={{ '& .MuiInputBase-input': { pr: '40px' } }}
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    position: 'absolute',
                                                    right: 10,
                                                    bottom: 22,
                                                }}
                                            >
                                                <PercentIcon />
                                            </InputAdornment>
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                )}

                {(touchpointType === 'api' || watch('audienceType') === 'authenticated') && (
                    <>
                        {clusterGroupFields.map((cg, index) => (
                            <Box key={cg.id}>
                                {index > 0 && (
                                    <Box width="100%" display="flex" alignItems="center" my={4}>
                                        <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                        <AndOrButton
                                            disabled={
                                                formDisabled ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                            }
                                            bgcolor="#FFBC00"
                                            value={watch(`clustersGroups.${index}.operatorWithPrevious`)}
                                            //onChange={(val) => setValue(`clustersGroups.${index}.operatorWithPrevious`, val)}
                                            onChange={onChangeOperatorWithPrevious}
                                        />
                                        <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                                    </Box>
                                )}

                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    sx={{ border: '1px solid #DFDFDF', borderRadius: 1, overflow: 'hidden' }}
                                >
                                    <ClusterGroup
                                        clusters={clusters}
                                        clusterGroupId={index}
                                        clusterOptions={clusterOptions}
                                    />
                                    {clusterGroupFields.length > 1 && (
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            sx={{ bgcolor: 'rgba(233, 233, 233, 1)' }}
                                            p={2}
                                        >
                                            <IconButton
                                                disabled={
                                                    clusterGroupFields.length === 1 ||
                                                    formDisabled ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                                                    currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                                                }
                                                onClick={() => clusterGroupRemove(index)}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </>
                )}
            </Box>
            {(touchpointType === 'api' || watch('audienceType') === 'authenticated') && (
                <Box width="100%" display="flex" alignItems="center" mt={4} mb={10}>
                    <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                    <IconButton
                        disabled={
                            watch(`clustersGroups.${clusterGroupFields.length - 1}.clusters`)[0] === ' ' ||
                            formDisabled ||
                            currentClientSurveyStatus === clientSurveyStatus.prodActive ||
                            currentClientSurveyStatus === clientSurveyStatus.prodNotActive
                        }
                        sx={{
                            width: '100px',
                            borderRadius: 1,
                            border: '1px solid #dfdfe4',
                        }}
                        onClick={() =>
                            clusterGroupAppend({
                                operatorWithPrevious: 'AND',
                                operatorInsideGroup: 'AND',
                                clusters: [' '],
                            })
                        }
                    >
                        <AddIcon />
                    </IconButton>
                    <Box width="100%" height="1px" bgcolor={'#dfdfe4'} />
                </Box>
            )}
        </Box>
    );
};

export default ComposeClusters;
