import React from 'react';
import { Head, router } from '@inertiajs/react';
import { trans } from 'matice';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import ToolContentPageLayout from '~/js/layouts/Tools/ToolContentPageLayout';
import EmptySection from '~/js/components/EmptySection';
import CtaButton from '~/js/components/CtaButton';
import TouchpointTable from './components/TouchpointTable';

const breadcrumbs = [
    {
        routeName: 'Touchpoint.index',
        routeLabel: 'Touchpoints',
    },
];

const TouchpointIndex = ({ touchpoints, canReadToolIds, can, brand, ...other }) => {
    console.log('TouchpointIndex props', { touchpoints, can, brand, ...other });

    const deleteTouchpoint = async (idTouchpoint) => {
        const promise = new Promise((resolve, reject) =>
            router.delete(route('Touchpoint.destroy', idTouchpoint), {
                onSuccess: () => {
                    toast.success(trans('touchpoint.touchpointDeleted'));
                    resolve();
                },
                onError: (e) => {
                    reject();
                    toast.error(!!e?.generic ? e.generic : trans('touchpoint.errorOnDeleteTouchpoint'));
                },
            }),
        );
        await promise;
    };

    const goToCreateTouchpointPage = () => router.get(route('Touchpoint.create', { brand }));

    return (
        <>
            <Head title={trans('touchpoint.touchpoint')} />
            <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                {touchpoints.length > 0 && can.create && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <CtaButton
                            variant="contained"
                            sx={{
                                px: 5,
                            }}
                            onClick={goToCreateTouchpointPage}
                        >
                            {trans('global.createNew')}
                        </CtaButton>
                    </Box>
                )}
                <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                    {trans('touchpoint.touchpoint')}
                </Typography>

                <TouchpointTable
                    touchpoints={touchpoints}
                    canReadToolIds={canReadToolIds}
                    deleteTouchpoint={deleteTouchpoint}
                    canUpdate={can.updateAny}
                    canDelete={can.deleteAny}
                />

                {touchpoints.length === 0 && (
                    <EmptySection
                        title={trans('touchpoint.noTouchpointTitle')}
                        description={can.create && trans('touchpoint.noTouchpointDescription')}
                        buttonComponent={
                            can.create && (
                                <CtaButton
                                    variant="contained"
                                    sx={{
                                        px: 5,
                                    }}
                                    onClick={goToCreateTouchpointPage}
                                >
                                    {trans('global.createNew')}
                                </CtaButton>
                            )
                        }
                    />
                )}
            </ToolContentPageLayout>
        </>
    );
};

export default TouchpointIndex;
