import React, { useEffect, useRef, useState } from 'react';
import { trans } from 'matice';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import axios from 'axios';
import AceEditorInput from './AceEditorInput';
import { FileInput, SelectInput, TextInput } from '~js/components/input';
import CtaButton from '~js/components/CtaButton';
import CustomTooltip from '~js/components/CustomTooltip';
import { REGEX_ONLY_PLAIN_CHAR } from '~js/constants/textValidation';

const SOURCE_TYPE_SELECT_OPTIONS = [
    { _id: 1, label: 'Connettore SQL', value: 'externalDataSource' },
    { _id: 2, label: 'File CSV', value: 'csv' },
];

const ClusterForm = ({
    triggers,
    externalDataSourceOption,
    missionTypes = [],
    onSubmit,
    onCancelPress,
    formDefaultValues,
    clusterCoverage = null,
    formDisable = false,
    canCreateOnlyCsvCluster,
    canAssociateTriggerToSQLCluster = false,
}) => {
    const {
        handleSubmit,
        watch,
        formState: { errors },
        control,
        setError,
        clearErrors,
        setValue,
        resetField,
    } = useForm({
        defaultValues: formDefaultValues
            ? formDefaultValues
            : {
                  type: canCreateOnlyCsvCluster ? 'csv' : '',
              },
    });

    const [isLoading, setIsLoading] = useState(false);
    const [loadingTestQuery, setLoadingTestQuery] = useState(false);
    const [columnIdentifierOptions, setColumnIdentifierOptions] = useState([]);
    const [coverage, setCoverage] = useState(!!clusterCoverage ? clusterCoverage : null);
    const firstBoot = useRef(true);
    const theme = useTheme();

    useEffect(() => {
        if (formDefaultValues?.columnIdentifier) {
            setValue('columnIdentifier', formDefaultValues?.columnIdentifier);

            setColumnIdentifierOptions([
                {
                    _id: formDefaultValues?.columnIdentifier,
                    label: formDefaultValues?.columnIdentifier,
                    value: formDefaultValues?.columnIdentifier,
                },
            ]);
        }
    }, [formDefaultValues]);

    useEffect(() => {
        if (!firstBoot.current) {
            setColumnIdentifierOptions([]);
            setValue('columnIdentifier', '');
        } else {
            firstBoot.current = false;
        }
    }, [watch('externalDataSource'), watch('query')]);

    const onSubmitHandler = async (data) => {
        setIsLoading(true);
        try {
            await onSubmit(data);
        } catch (e) {
            Object.keys(e).forEach((key) => {
                setError(key, { type: 'custom', message: e[key] });
            });
        } finally {
            setIsLoading(false);
        }
    };

    const testQuery = async () => {
        clearErrors();
        setLoadingTestQuery(true);
        setCoverage(null);
        try {
            const res = await axios.post(route('Cluster.testCluster'), {
                query: watch('query'),
                externalDataSource: watch('externalDataSource'),
            });
            const columns = res?.data?.data?.columns ? res?.data?.data?.columns : [];
            setColumnIdentifierOptions(columns.map((c) => ({ _id: c, label: c, value: c })));
            setCoverage(res?.data?.data?.coverage);
            toast.success(trans('cluster.queryTestPerformedSuccessfully'));
        } catch (e) {
            if (e.response.status === 412) {
                toast.error(e.response.data.message);
                return;
            }
            if (e.response.status === 422) {
                const errorMessageValidations = e?.response?.data?.message ? e?.response?.data?.message : {};

                if (typeof errorMessageValidations === 'object') {
                    Object.keys(errorMessageValidations).forEach((key) => {
                        setError(key, {
                            type: 'custom',
                            message: errorMessageValidations[key][0],
                        });
                    });
                }

                if (typeof errorMessageValidations === 'string') {
                    toast.error(errorMessageValidations);
                }
                return;
            }
            toast.error(e?.response?.data?.message);
        } finally {
            setLoadingTestQuery(false);
        }
    };

    const testCsv = async () => {
        clearErrors();
        setLoadingTestQuery(true);
        setCoverage(null);
        try {
            const formData = new FormData();
            formData.append('separator', watch('separator'));
            formData.append('enclosure', watch('enclosure'));
            formData.append('escape', watch('escape'));
            formData.append('file', watch('file'));
            const res = await axios.post(route('Cluster.testCluster'), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const columns = res?.data?.data?.columns ? res?.data?.data?.columns : [];
            setColumnIdentifierOptions(columns.map((c) => ({ _id: c, label: c, value: c })));
            setCoverage(res?.data?.data?.coverage);
            toast.success(trans('cluster.csvTestPerformedSuccessfully'));
        } catch (e) {
            if (e.response.status === 412) {
                toast.error(e.response.data.message);
                return;
            }
            if (e.response.status === 422) {
                const errorMessageValidations = e?.response?.data?.message ? e?.response?.data?.message : {};

                if (typeof errorMessageValidations === 'object') {
                    Object.keys(errorMessageValidations).forEach((key) => {
                        setError(key, {
                            type: 'custom',
                            message: errorMessageValidations[key][0],
                        });
                    });
                }

                if (typeof errorMessageValidations === 'string') {
                    toast.error(errorMessageValidations);
                }
                return;
            }
            toast.error(e?.response?.data?.message);
        } finally {
            setLoadingTestQuery(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
            <Box
                sx={{
                    position: 'absolute',
                    top: -25,
                    width: '100%',
                    bgcolor: theme.palette.primary.textBackground,
                    marginLeft: -4,
                    marginRight: 4,
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    px: 4,
                    py: 0.8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <InfoIcon color="primary" fontSize="small" sx={{ mr: 1 }} />
                <Typography sx={{ textAlign: 'center' }} color="primary">
                    {trans('cluster.alertExternalDataSource')}
                </Typography>
            </Box>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="title"
                            rules={{
                                required: trans('formValidation.requiredField'),
                                minLength: { value: 3, message: trans('formValidation.fieldTooShort') },
                                maxLength: { value: 50, message: trans('formValidation.fieldTooLong') },
                                pattern: {
                                    value: REGEX_ONLY_PLAIN_CHAR,
                                    message: trans('formValidation.charNotAllowed'),
                                },
                            }}
                            defaultValue=""
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisable}
                                    label={trans('cluster.title')}
                                    placeholder={trans('cluster.title')}
                                    helperText={errors?.title?.message}
                                    error={!!errors?.title}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="type"
                            rules={{
                                required: trans('formValidation.requiredField'),
                            }}
                            defaultValue={canCreateOnlyCsvCluster ? 'csv' : ''}
                            render={({ field }) => (
                                <SelectInput
                                    disabled={formDisable || !!formDefaultValues || canCreateOnlyCsvCluster}
                                    placeholderLabel={trans('cluster.selectOneSource')}
                                    label={trans('cluster.source')}
                                    selectOptions={SOURCE_TYPE_SELECT_OPTIONS}
                                    helperText={errors?.type?.message}
                                    error={!!errors?.type}
                                    {...field}
                                />
                            )}
                        />
                        {watch('type') === 'externalDataSource' && (
                            <Controller
                                control={control}
                                name="externalDataSource"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                shouldUnregister
                                defaultValue=""
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={formDisable}
                                        label={trans('cluster.connection')}
                                        selectOptions={externalDataSourceOption}
                                        helperText={errors?.externalDataSource?.message}
                                        error={!!errors?.externalDataSource}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                        {watch('type') === 'csv' && (
                            <Controller
                                control={control}
                                name="enclosure"
                                shouldUnregister
                                defaultValue='"'
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisable || !!formDefaultValues}
                                        label={trans('cluster.enclosure')}
                                        helperText={errors?.enclosure?.message}
                                        error={!!errors?.enclosure}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Controller
                            control={control}
                            name="description"
                            rules={{
                                maxLength: { value: 1000, message: trans('formValidation.fieldTooLong') },
                                pattern: {
                                    value: REGEX_ONLY_PLAIN_CHAR,
                                    message: trans('formValidation.charNotAllowed'),
                                },
                            }}
                            defaultValue=""
                            render={({ field }) => (
                                <TextInput
                                    disabled={formDisable}
                                    label={trans('cluster.description')}
                                    placeholder={trans('cluster.description')}
                                    helperText={errors?.description?.message}
                                    error={!!errors?.description}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="missionTypes"
                            shouldUnregister
                            defaultValue={[]}
                            render={({ field }) => (
                                <SelectInput
                                    multiple
                                    disabled={formDisable}
                                    tooltipText={trans('cluster.associateToolsToCluster')}
                                    placeholderLabel={trans('formValidation.selectMultiplePlaceholder')}
                                    label={trans('cluster.tools')}
                                    selectOptions={missionTypes}
                                    helperText={errors?.tools?.message}
                                    error={!!errors?.tools}
                                    required
                                    {...field}
                                />
                            )}
                        />
                        {watch('type') === 'externalDataSource' && canAssociateTriggerToSQLCluster && (
                            <Controller
                                control={control}
                                name="triggers"
                                shouldUnregister
                                defaultValue={[]}
                                render={({ field }) => (
                                    <SelectInput
                                        multiple
                                        disabled={formDisable}
                                        tooltipText={
                                            !canAssociateTriggerToSQLCluster
                                                ? trans('cluster.associateTriggerToSQLDisabledTooltip')
                                                : null
                                        }
                                        placeholderLabel={trans('formValidation.selectMultipleOptionalPlaceholder')}
                                        label={trans('cluster.triggers')}
                                        selectOptions={triggers}
                                        helperText={errors?.triggers?.message}
                                        error={!!errors?.triggers}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                        {watch('type') === 'csv' && (
                            <Controller
                                control={control}
                                name="separator"
                                shouldUnregister
                                defaultValue=","
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisable || !!formDefaultValues}
                                        label={trans('cluster.separator')}
                                        helperText={errors?.separator?.message}
                                        error={!!errors?.separator}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                        {watch('type') === 'csv' && (
                            <Controller
                                control={control}
                                name="escape"
                                shouldUnregister
                                defaultValue="\"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                render={({ field }) => (
                                    <TextInput
                                        disabled={formDisable || !!formDefaultValues}
                                        label={trans('cluster.escape')}
                                        helperText={errors?.escape?.message}
                                        error={!!errors?.escape}
                                        {...field}
                                    />
                                )}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        {watch('type') === 'externalDataSource' && (
                            <>
                                <Controller
                                    control={control}
                                    name="query"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    shouldUnregister
                                    defaultValue=""
                                    render={({ field: { value, onChange } }) => (
                                        <AceEditorInput
                                            disabled={formDisable}
                                            helperText={errors?.query?.message}
                                            error={!!errors?.query}
                                            label={trans('cluster.query')}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                                <Box display="flex" justifyContent="flex-end" mt="-40px">
                                    <CtaButton
                                        onClick={testQuery}
                                        loading={loadingTestQuery}
                                        sx={{ px: 0 }}
                                        disabled={formDisable}
                                    >
                                        <Typography sx={{ textDecoration: 'underline' }}>
                                            {trans('cluster.testQuery')}
                                        </Typography>
                                    </CtaButton>
                                </Box>
                            </>
                        )}

                        {watch('type') === 'csv' && (
                            <>
                                <Controller
                                    control={control}
                                    name="file"
                                    rules={
                                        !formDefaultValues && {
                                            required: trans('formValidation.requiredField'),
                                        }
                                    }
                                    defaultValue=""
                                    shouldUnregister
                                    render={({ field: { onChange, value } }) => (
                                        <FileInput
                                            tooltipText=""
                                            disabled={formDisable || !!formDefaultValues}
                                            accept=".csv"
                                            label="CSV"
                                            placeholder={
                                                !!formDefaultValues
                                                    ? trans('cluster.fileNotEdited')
                                                    : trans('cluster.selectCsvToUpload')
                                            }
                                            value={value}
                                            onChange={(e) => {
                                                onChange(e);
                                                resetField('columnIdentifier');
                                                setColumnIdentifierOptions([]);
                                                testCsv();
                                            }}
                                            helperText={errors?.file?.message}
                                            error={!!errors?.file}
                                        />
                                    )}
                                />
                                <Box display="flex" justifyContent="flex-end" mt="-40px">
                                    <CtaButton
                                        onClick={testCsv}
                                        loading={loadingTestQuery}
                                        sx={{ px: 0 }}
                                        disabled={formDisable || !!formDefaultValues}
                                    >
                                        <Typography sx={{ textDecoration: 'underline' }}>
                                            {trans('cluster.testCsv')}
                                        </Typography>
                                    </CtaButton>
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ maxWidth: '500px' }}>
                        {!!watch('type') && (
                            <Box
                                sx={{
                                    margin: '38px 0',
                                    border: '1px solid #8C939D',
                                    bgcolor: '#F6F6F6',
                                    padding: '20px 12px',
                                    borderRadius: '5px',
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography variant="h6" mr={4}>
                                        {trans('cluster.coverage')}
                                    </Typography>
                                    <CustomTooltip
                                        title={trans('cluster.coverageTooltip')}
                                        placement="bottom-start"
                                        sx={{ zIndex: 99 }}
                                    >
                                        <InfoIcon sx={{ color: '#828282', zIndex: 99 }} />
                                    </CustomTooltip>
                                </Box>

                                <Typography variant="h6" mr={4}>
                                    {coverage}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>

            {/*<Controller
                    control={control}
                    name="query"
                    render={({ field }) => (
                        <TextInput
                            label={trans('cluster.query')}
                            placeholder={trans('cluster.query')}
                            helperText={errors?.query?.message}
                            error={!!errors?.query}
                            multiline
                            minRows={2}
                            {...field}
                            ref={null}
                        />
                    )}
                />*/}

            {columnIdentifierOptions?.length > 0 && (
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{ maxWidth: '500px' }}>
                            <Controller
                                control={control}
                                name="columnIdentifier"
                                rules={{
                                    required: trans('formValidation.requiredField'),
                                }}
                                shouldUnregister
                                defaultValue=""
                                render={({ field }) => (
                                    <SelectInput
                                        disabled={formDisable || (watch('type') === 'csv' && !!formDefaultValues)}
                                        label={trans('cluster.columnIdentifier')}
                                        selectOptions={columnIdentifierOptions}
                                        helperText={errors?.columnIdentifier?.message}
                                        error={!!errors?.columnIdentifier}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    mt: 5,
                }}
            >
                <CtaButton
                    variant="outlined"
                    type="button"
                    disabled={isLoading || loadingTestQuery}
                    onClick={onCancelPress}
                >
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton
                    disabled={loadingTestQuery || !watch('columnIdentifier') || formDisable}
                    variant="contained"
                    type="submit"
                    loading={isLoading}
                >
                    {trans('global.saveChanges')}
                </CtaButton>
            </Box>
        </Box>
    );
};

export default ClusterForm;
