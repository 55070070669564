import React, { useEffect, useState } from 'react';
import CustomModal from '~js/components/CustomModal';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { trans } from 'matice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { QRCodeSVG } from 'qrcode.react';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';

const ModalTestSurveyWeb = ({ show, onClose, surveyId }) => {
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetchTestLink();
    }, []);

    const fetchTestLink = async () => {
        try {
            setIsLoading(true);
            const res = await axios.get(route('Surveys.testLinkWeb', { id: surveyId }));
            //data: {data: {link: 'https://test.com', expiresAt: '2024-01-01'}}
            if (res?.data?.data) {
                setResponse(res.data.data);
                return;
            }
            setError(true);
        } catch (error) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const onCopyLink = () => {
        if (!response?.link) return;
        navigator.clipboard.writeText(response?.link);
        toast.success(trans('survey.linkCopied'));
    };

    const onOpenLink = () => {
        if (!response?.link) return;
        window.open(response?.link, '_blank');
    };

    return (
        <CustomModal
            open={show}
            handleClose={isLoading ? () => null : onClose}
            sx={{ maxWidth: '700px', borderRadius: 3, p: 0 }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                    justifyContent: 'center',
                    p: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mb: 1,
                    }}
                >
                    <IconButton onClick={onClose}>
                        <CancelIcon fontSize="large" color="primary" />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <Box
                        sx={{
                            bgcolor: (theme) => theme.palette.primary.main,
                            width: 30,
                            height: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 500,
                            p: 2.5,
                        }}
                    >
                        <PageviewOutlinedIcon sx={{ color: 'white' }} />
                    </Box>
                </Box>
                <Box mx={2}>
                    <Typography variant="h5" sx={{ fontWeight: 700, textAlign: 'center' }}>
                        {trans('survey.testSurveyOnYourDevice')}
                    </Typography>
                    <Box sx={{ backgroundColor: 'rgba(248, 248, 248, 1)', px: 2, py: 4, borderRadius: 2, mt: 4 }}>
                        {isLoading && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100 }}>
                                <CircularProgress color="inherit" />
                            </Box>
                        )}
                        {!isLoading && !error && response?.link && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <QRCodeSVG value={response?.link} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'stretch',
                                        justifyContent: 'center',
                                        backgroundColor: 'white',
                                        borderRadius: 2,
                                        overflow: 'hidden',
                                        mt: 4,
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                                        <IconButton onClick={onCopyLink}>
                                            <FileCopyOutlinedIcon sx={{ color: 'rgba(115, 115, 115, 1)' }} />
                                        </IconButton>
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                            {response?.link}
                                        </Typography>
                                    </Box>
                                    <Box
                                        onClick={onOpenLink}
                                        sx={{
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            p: 2,
                                            borderTopRightRadius: 2,
                                            borderBottomRightRadius: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                        }}
                                    >
                                        <Typography sx={{ color: 'white' }}>{trans('global.go')}</Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{ textAlign: 'center', mt: 2, fontSize: 12, color: 'rgba(115, 115, 115, 1)' }}
                                >
                                    {trans('survey.testSurveyWebDescription')}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    {!isLoading && !error && response?.expiresAt && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
                            <Typography>
                                {trans('survey.linkExpiredAt', {
                                    args: {
                                        time: format(new Date(response?.expiresAt), 'HH:mm:ss'),
                                        date: format(new Date(response?.expiresAt), 'dd/MM/yyyy'),
                                    },
                                })}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </CustomModal>
    );
};

export default ModalTestSurveyWeb;
