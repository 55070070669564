import React, { forwardRef } from 'react';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { CustomTextInput } from './TextInput';
import { alpha } from '@mui/material/styles';
import { trans } from 'matice';

const SelectInputImage = forwardRef(
    (
        {
            label = null,
            tooltipText = null,
            placeholderLabel = null,
            type = 'text',
            error,
            helperText,
            selectOptions = [],
            defaultValue = '',
            multiple,
            value,
            ...other
        },
        ref,
    ) => {
        return (
            <Tooltip title={tooltipText}>
                <FormControl variant="standard" fullWidth error={error} sx={{ marginBottom: 2 }}>
                    {label && (
                        <InputLabel
                            shrink
                            htmlFor="demo-customized-select"
                            sx={{
                                color: '#5E6366',
                                pl: 1,
                                '&.Mui-focused': { color: error ? '#F9473C' : '#5E6366 !important' },
                            }}
                        >
                            {label}
                        </InputLabel>
                    )}
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        input={<CustomTextInput />}
                        defaultValue={defaultValue}
                        displayEmpty
                        sx={[
                            helperText && {
                                boxShadow: (theme) => `${alpha(theme.palette.error.main, 0.5)} 0 0 0 0.2rem`,
                                borderColor: (theme) => theme.palette.error.main,
                                borderRadius: '8px',
                            },
                        ]}
                        multiple={multiple}
                        value={value}
                        renderValue={(selectedValue) =>
                            multiple ? (
                                Array.isArray(selectedValue) && selectedValue?.length > 0 ? (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selectedValue?.map((value) => (
                                            <Chip
                                                key={value}
                                                label={selectOptions?.find((o) => o?.value === value)?.label}
                                            />
                                        ))}
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        <Typography variant={'label'}>
                                            {placeholderLabel ?? trans('formValidation.selectMultiplePlaceholder')}
                                        </Typography>
                                    </Box>
                                )
                            ) : selectedValue !== undefined && selectedValue !== null && selectedValue.length !== '' ? (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    {selectOptions?.find((o) => o.value === selectedValue)?.image && (
                                        <Box
                                            width={40}
                                            height={40}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            border={1}
                                            borderRadius="6px"
                                            marginRight="10px"
                                            borderColor="rgba(0, 0, 0, 0.1)"
                                        >
                                            <Box
                                                component="img"
                                                src={selectOptions?.find((o) => o.value === selectedValue)?.image}
                                                maxWidth={30}
                                            ></Box>
                                        </Box>
                                    )}
                                    {selectOptions?.find((o) => o.value === selectedValue)?.label}
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    <Typography variant={'label'}>
                                        {placeholderLabel ?? trans('formValidation.selectPlaceholder')}
                                    </Typography>
                                </Box>
                            )
                        }
                        {...other}
                        ref={ref}
                    >
                        {selectOptions?.map((op, index) => {
                            return (
                                <MenuItem
                                    key={`${op.value}-${index}`}
                                    value={op.value}
                                    sx={index > 0 ? { borderTop: '1px solid #dfdfe4' } : {}}
                                >
                                    {op.image && (
                                        <Box
                                            width={40}
                                            height={40}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            border={1}
                                            borderRadius="6px"
                                            marginRight="10px"
                                            borderColor="rgba(0, 0, 0, 0.1)"
                                        >
                                            <Box component="img" src={op.image} maxWidth={30}></Box>
                                        </Box>
                                    )}
                                    {multiple && <Checkbox checked={value?.indexOf(op.value) > -1} />}
                                    {op.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </FormControl>
            </Tooltip>
        );
    },
);

export default SelectInputImage;
