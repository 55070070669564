import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { trans } from 'matice';
import { Box, Paper, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import AuthLayout from '~js/layouts/AuthLayout';
import TextInput from '~js/components/TextInput';
import CtaButton from '~js/components/CtaButton';
import toolsLogo from '~/assets/images/loyalty-built-logo.png';

const TwoFactorCodePage = () => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setError,
    } = useForm({
        defaultValues: {
            code: '',
        },
    });

    const [loading, setLoading] = useState(false);

    const logout = () => router.get(route('logout'));

    const onSubmit = async (data) => {
        const { code } = data;
        router.post(
            route('ConfirmTwoFactorCode'),
            { code },
            {
                onStart: () => setLoading(true),
                onFinish: () => setLoading(false),
                onError: (e) => {
                    if (!!e?.generic) {
                        toast.error(e.generic);
                        return;
                    }
                    Object.keys(e).forEach((key) => {
                        setError(key, { type: 'custom', message: e[key] });
                    });
                },
            },
        );
    };
    return (
        <>
            <Head title={trans('auth.loginActivationCode2fa.subject')} />
            <AuthLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: 750,
                                p: 8,
                            }}
                        >
                            <Box component="img" src={toolsLogo} maxWidth="194px" alt={'Logo tools loyalty'} mb={3} />
                            <Typography variant="h6" mt={3} mb={6}>
                                {trans('auth.loginActivationCode2fa.insertReceivedCode')}
                            </Typography>
                            <Box width="100%" maxWidth="400px">
                                <Controller
                                    control={control}
                                    name="code"
                                    rules={{
                                        required: trans('formValidation.requiredField'),
                                    }}
                                    render={({ field }) => (
                                        <TextInput
                                            type="text"
                                            label={trans('auth.loginActivationCode2fa.code')}
                                            placeholder={trans('auth.loginActivationCode2fa.codePlaceholder')}
                                            helperText={errors?.code?.message}
                                            error={!!errors?.code}
                                            {...field}
                                        />
                                    )}
                                />
                                <CtaButton
                                    disabled={loading}
                                    type="button"
                                    sx={{ px: 0, textDecoration: 'underline' }}
                                    onClick={logout}
                                >
                                    {trans('auth.resetPassword.returnToLogin')}
                                </CtaButton>
                            </Box>
                            <CtaButton type="submit" variant="contained" sx={{ px: 6, mt: 6 }} loading={loading}>
                                {trans('auth.login.title')}
                            </CtaButton>
                        </Paper>
                    </Box>
                </form>
            </AuthLayout>
        </>
    );
};

export default TwoFactorCodePage;
