import React, { useState } from 'react';
import AuthLayout from '~js/layouts/AuthLayout';
import { Head, router } from '@inertiajs/react';
import { trans } from 'matice';
import { Box, Paper, Typography } from '@mui/material';
import SelectInputImage from '~js/components/SelectInputImage';
import CtaButton from '~js/components/CtaButton';
import getBrandsOptions from '~/js/utils/getBrandsOptions';

const BrandIndex = ({ userBrandsWithRoles, ...props }) => {
    console.log('BrandIndex props', { userBrandsWithRoles, ...props });
    const [selectedBrand, setSelectedBrand] = useState(userBrandsWithRoles?.[0]?.identifier);

    const onProceedClick = () => {
        if (selectedBrand) {
            // quick fix to redirect to the surveys page with the selected brand to avoid error with navigation withouth brand
            window.location.href = route('Surveys.index', { brand: selectedBrand });
            //router.get(route('Surveys.index', { brand: selectedBrand }));
        }
    };

    const brandOptions = getBrandsOptions(userBrandsWithRoles);

    return (
        <>
            <Head title={trans('auth.brand.selectBrand')} />
            <AuthLayout>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <Paper
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minWidth: 750,
                            p: 8,
                        }}
                    >
                        <Typography variant="h4">{trans('auth.brand.selectBrand')}</Typography>
                        <Typography mt={2}>{trans('auth.brand.selectBrandDescription')}</Typography>
                        <Box sx={{ maxWidth: '500px', width: '100%', mt: 4 }}>
                            <SelectInputImage
                                placeholderLabel={trans('cluster.selectOneSource')}
                                label={trans('auth.brand.brand')}
                                value={selectedBrand}
                                onChange={(e) => setSelectedBrand(e.target.value)}
                                selectOptions={brandOptions}
                            />
                        </Box>
                        <CtaButton
                            disabled={!selectedBrand}
                            variant="contained"
                            sx={{ px: 6, mt: 6 }}
                            onClick={onProceedClick}
                        >
                            {trans('global.proceed')}
                        </CtaButton>
                    </Paper>
                </Box>
            </AuthLayout>
        </>
    );
};

export default BrandIndex;
