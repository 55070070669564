import React from 'react';

import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TouchpointCardType = ({ text, onClick, selected }) => {
    return (
        <Box
            component="button"
            type="button"
            sx={{
                cursor: 'pointer',
                userSelect: 'none',
                backgroundColor: 'rgba(250, 250, 250, 1)',
                border: (th) =>
                    selected ? `1px solid ${th.palette.primary.main}` : '1px solid rgba(217, 217, 217, 1)',
                borderRadius: '8px',
                p: 2,
                py: 3,
                width: '50%',
                maxWidth: '400px',
                display: 'flex',
                justifyContent: 'space-between',
                color: (th) => (selected ? th.palette.primary.main : 'rgba(0, 0, 0, 0.87)'),
            }}
            onClick={onClick}
        >
            <Typography>{text}</Typography>
            <KeyboardArrowDownIcon sx={{ fontSize: 30 }} />
        </Box>
    );
};

export default TouchpointCardType;
