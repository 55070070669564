import React from 'react';
import { Box, Typography } from '@mui/material';
import { trans } from 'matice';
import { Controller, useFormContext } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import TextInput from '~/js/components/TextInput';
import CtaButton from '~/js/components/CtaButton';

const TouchpointApiForm = ({ onCancelPress, loading, touchpointToEdit }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(250, 250, 250, 1)',
                my: 4,
                p: 2,
                border: '1px solid rgba(217, 217, 217, 1)',
                borderRadius: '8px',
                maxWidth: '820px',
            }}
        >
            <Typography sx={{ mb: 4 }}>{trans('touchpoint.clientApi')}</Typography>
            <Controller
                control={control}
                name="name"
                shouldUnregister
                defaultValue={touchpointToEdit?.name || ''}
                rules={{
                    required: trans('formValidation.requiredField'),
                }}
                render={({ field }) => (
                    <TextInput
                        label={trans('touchpoint.name')}
                        helperText={errors?.name?.message}
                        error={!!errors?.name}
                        {...field}
                    />
                )}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
                <InfoOutlinedIcon color="primary" />
                <Typography color="primary" sx={{ fontSize: 14, fontStyle: 'italic' }}>
                    {trans('touchpoint.apiAlert')}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1, mt: 4, justifyContent: 'space-between' }}>
                <CtaButton variant="outlined" onClick={onCancelPress} disabled={loading}>
                    {trans('global.cancel')}
                </CtaButton>
                <CtaButton variant="contained" type="submit" loading={loading}>
                    {trans('touchpoint.addTouchpoint')}
                </CtaButton>
            </Box>
        </Box>
    );
};

export default TouchpointApiForm;
