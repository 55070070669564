import React, { useRef, useState } from 'react';
import { trans } from 'matice';
import { Box, IconButton } from '@mui/material';
import { DataGrid, itIT } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmCancelModal from '~/js/components/ConfirmCancelModal';
import { router } from '@inertiajs/react';

const TouchpointTable = ({ touchpoints, deleteTouchpoint, canReadToolIds = false, canUpdate, canDelete }) => {
    const touchpointIdToDelete = useRef(null);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const deleteClusterHandler = async () => {
        setLoadingDelete(true);
        try {
            await deleteTouchpoint(touchpointIdToDelete.current);
            onCloseModalDeleteHandler();
        } finally {
            setLoadingDelete(false);
        }
    };

    const onCloseModalDeleteHandler = () => {
        setShowDeleteConfirmModal(false);
        touchpointIdToDelete.current = null;
    };

    const onEditPress = (itemId) => {
        router.get(route('Touchpoint.edit', itemId));
    };

    const renderCellAction = (params) => {
        const { row } = params;

        const onDeletePress = () => {
            touchpointIdToDelete.current = params.id;
            setShowDeleteConfirmModal(true);
        };

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <IconButton onClick={() => onEditPress(params.id)} disabled={!canUpdate}>
                    <EditOutlinedIcon />
                </IconButton>
                <IconButton onClick={onDeletePress} disabled={!canDelete}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            </Box>
        );
    };

    const columns = [
        {
            field: 'name',
            headerName: trans('touchpoint.name'),
            flex: 1,
        },
        {
            field: 'type',
            headerName: trans('touchpoint.typology'),
            flex: 1,
        },
        {
            headerName: ' ',
            field: ' ',
            flex: 0.5,
            sortable: false,
            renderCell: renderCellAction,
            minWidth: 80,
        },
    ];

    return (
        <>
            {touchpoints.length > 0 && (
                <DataGrid
                    rows={touchpoints}
                    columns={[
                        ...(canReadToolIds
                            ? [
                                  {
                                      field: '_id',
                                      headerName: 'ID',
                                      flex: 1,
                                  },
                              ]
                            : []),
                        ...columns,
                    ]}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    rowLength={5}
                    maxColumns={6}
                    localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                    getRowId={(r) => r._id}
                    // onRowClick={r => goToSummaryPage(r.id)}
                />
            )}
            <ConfirmCancelModal
                iconType="delete"
                show={showDeleteConfirmModal}
                onClose={onCloseModalDeleteHandler}
                title={trans('touchpoint.deleteTouchpointTitle')}
                description={trans('touchpoint.deleteTouchpointDescription')}
                onConfirm={deleteClusterHandler}
                loading={loadingDelete}
            />
        </>
    );
};

export default TouchpointTable;
