import React, { useContext, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { it } from 'date-fns/locale';
import { usePage } from '@inertiajs/react';
import { getResponsiveTheme } from '~js/constants/themes';
import ToolNavBarLayout from '~js/layouts/Tools/ToolNavBarLayout';
import ToolContext from '~js/layouts/Tools/context';

const componentsWithoutSidebar = [
    'TesterGroupsAcceptInvitation',
    'LoginPage',
    'ForgotPasswordPage',
    'ResetPasswordPage',
    'TwoFactorCodePage',
    'UpdateExpiredPasswordPage',
];

const ToolLayout = ({ routePrefix, children }) => {
    const {
        component,
        props: { userBrandsWithRoles },
    } = usePage();
    const { brand } = useContext(ToolContext);

    const theme = useMemo(() => {
        const brandTheme = userBrandsWithRoles?.find((b) => b.identifier === brand)?.settings?.theme;
        return getResponsiveTheme(brand, brandTheme);
    }, [brand]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
            <ThemeProvider theme={theme}>
                {!componentsWithoutSidebar.includes(component) ? (
                    <ToolNavBarLayout routePrefix={routePrefix}>{children}</ToolNavBarLayout>
                ) : (
                    <>{children}</>
                )}
            </ThemeProvider>
            <ToastContainer position="top-right" />
        </LocalizationProvider>
    );
};

export default ToolLayout;
