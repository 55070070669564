import React, { useState } from 'react';
import { Head, router } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { trans } from 'matice';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import ToolContentPageLayout from '~/js/layouts/Tools/ToolContentPageLayout';
import TouchpointCardType from './components/TouchpointCardType';
import TouchpointWebsiteForm from './components/TouchpointWebsiteForm';
import TouchpointApiForm from './components/TouchpointApiForm';
import CtaButton from '~/js/components/CtaButton';
import SuccessModal from '~/js/components/SuccessModal';

const breadcrumbs = [
    {
        routeName: 'Touchpoint.create',
        routeLabel: 'Crea Touchpoint',
    },
    {
        routeName: 'Touchpoint.index',
        routeLabel: 'Touchpoints',
    },
];

const TouchpointCreate = ({ brand, ...other }) => {
    console.log(other);
    const formMethods = useForm();

    const { handleSubmit, watch, setValue } = formMethods;

    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const goToTouchpointListPage = () => router.get(route('Touchpoint.index', { brand }));

    const onSubmit = async (data) => {
        console.log(data);

        let dataToSubmit = null;

        if (data.type === 'web') {
            dataToSubmit = {
                type: data.type,
                name: data.name,
                domain: data.domain,
                urls: data.urls,
                font: data.font,
                radius: {
                    cta: data.radiusCta,
                    modal: data.radiusModal,
                    box: data.radiusBox,
                },
                palette: {
                    primary: data.primaryColor,
                    secondary: data.secondaryColor,
                    text: data.textColor,
                },
            };
        }

        if (data.type === 'api') {
            dataToSubmit = data;
        }

        const promise = new Promise((resolve, reject) =>
            router.post(route('Touchpoint.store'), dataToSubmit, {
                onError: (e) => {
                    reject(e);
                    if (!!e?.generic) {
                        toast.error(e.generic);
                    }
                },
                onSuccess: (data) => {
                    const touchpointCreated = data?.props?.touchpoint;
                    if (
                        touchpointCreated &&
                        touchpointCreated.type === 'api' &&
                        touchpointCreated.client_id &&
                        touchpointCreated.client_secret &&
                        touchpointCreated.name
                    ) {
                        const { client_id, client_secret, name } = touchpointCreated;
                        const fileContent = `Touchpoint Name: ${name}\nClient ID: ${client_id}\nClient Secret: ${client_secret}`;
                        const blob = new Blob([fileContent], { type: 'text/plain' });
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'touchpoint_credentials.txt';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        URL.revokeObjectURL(url);
                    }
                    resolve();
                    setShowModalSuccess(true);
                },
            }),
        );

        try {
            setLoading(true);
            await promise;
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <FormProvider {...formMethods}>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Head title={trans('touchpoint.createNewTouchpoint')} />
                    <ToolContentPageLayout breadcrumbItems={breadcrumbs}>
                        <Typography variant="h6" sx={{ fontWeight: 600, mb: 4 }}>
                            {trans('touchpoint.configureNewTouchpoint')}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <TouchpointCardType
                                text="Website"
                                selected={watch('type') === 'web'}
                                onClick={() => setValue('type', 'web')}
                            />
                            <TouchpointCardType
                                text={trans('touchpoint.clientApi')}
                                selected={watch('type') === 'api'}
                                onClick={() => setValue('type', 'api')}
                            />
                        </Box>

                        {watch('type') === 'web' && (
                            <TouchpointWebsiteForm onCancelPress={goToTouchpointListPage} loading={loading} />
                        )}
                        {watch('type') === 'api' && (
                            <TouchpointApiForm onCancelPress={goToTouchpointListPage} loading={loading} />
                        )}
                        {!watch('type') && (
                            <CtaButton variant="outlined" onClick={goToTouchpointListPage} sx={{ mt: 6 }}>
                                {trans('global.goBack')}
                            </CtaButton>
                        )}
                    </ToolContentPageLayout>
                </Box>
            </FormProvider>
            <SuccessModal
                open={showModalSuccess}
                handleClose={() => setShowModalSuccess(false)}
                title={trans('global.compliments')}
                description={trans('touchpoint.touchpointCreatedSuccess')}
                buttonText={trans('touchpoint.backToTouchpointList')}
                onPressButton={goToTouchpointListPage}
                onExitPress={() => setShowModalSuccess(false)}
            />
        </>
    );
};

export default TouchpointCreate;
