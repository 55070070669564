import React from 'react';
import { __ } from 'matice';

export const Images = {
    INTRODUCTION_CLOSURE: 'INTRODUCTION_CLOSURE',
    SURVEY_INTRO_IMAGE: 'SURVEY_INTRO_IMAGE',
    RATING: 'RATING',
    CHOICE_IMAGE_GRID: 'CHOICE_IMAGE_GRID',
    CHOICE_IMAGE_LIST: 'CHOICE_IMAGE_LIST',
    QUIZ_CLASSIC_START_BG: 'QUIZ_CLASSIC_START_BG',
    QUIZ_CLASSIC_END_BG: 'QUIZ_CLASSIC_END_BG',
    QUIZ_CLASSIC_PREVIEW_BG: 'QUIZ_CLASSIC_PREVIEW_BG',
    QUIZ_CLASSIC_CARD_BG: 'QUIZ_CLASSIC_CARD_BG',
    QUIZ_DIFFERENCE_IMAGE: 'QUIZ_DIFFERENCE_IMAGE',
    QUIZ_CARD_ICON: 'QUIZ_CARD_ICON',
    QUIZ_CHALLENGE_MODAL_IMAGE: 'QUIZ_CHALLENGE_MODAL_IMAGE',
};

export const IMG_DIMENSIONS = {
    INTRODUCTION_CLOSURE: { WIDTH: 819, HEIGHT: 498 },
    SURVEY_INTRO_IMAGE: { WIDTH: 363, HEIGHT: 316 },
    SURVEY_WEB_INTRO_IMAGE: { WIDTH: 960, HEIGHT: 480 },
    RATING: { WIDTH: 327, HEIGHT: 182 },
    CHOICE_IMAGE_GRID: { WIDTH: 164, HEIGHT: 263 },
    CHOICE_IMAGE_LIST: { WIDTH: 327, HEIGHT: 182 },
    QUIZ_CLASSIC_START_BG: { WIDTH: 750, HEIGHT: 1224 },
    QUIZ_CLASSIC_END_BG: { WIDTH: 750, HEIGHT: 1224 },
    QUIZ_CLASSIC_PREVIEW_BG: { WIDTH: 750, HEIGHT: 1224 },
    QUIZ_CLASSIC_CARD_BG: { WIDTH: 1400, HEIGHT: 2226 },
    QUIZ_DIFFERENCE_IMAGE: { WIDTH: 1029, HEIGHT: 579 },
    QUIZ_CARD_ICON: { WIDTH: 234, HEIGHT: 184 },
    QUIZ_MEMORY_IMAGE: { WIDTH: 384, HEIGHT: 289 },
    QUIZ_DRAG_IMAGE: { WIDTH: 191, HEIGHT: 191 },
    QUIZ_CHALLENGE_MODAL_IMAGE: { WIDTH: 200, HEIGHT: 200 },
};

export const ASPECT_RATIO_IMG = {
    INTRODUCTION_CLOSURE: IMG_DIMENSIONS.INTRODUCTION_CLOSURE.WIDTH / IMG_DIMENSIONS.INTRODUCTION_CLOSURE.HEIGHT,
    SURVEY_INTRO_IMAGE: IMG_DIMENSIONS.SURVEY_INTRO_IMAGE.WIDTH / IMG_DIMENSIONS.SURVEY_INTRO_IMAGE.HEIGHT,
    SURVEY_WEB_INTRO_IMAGE: IMG_DIMENSIONS.SURVEY_WEB_INTRO_IMAGE.WIDTH / IMG_DIMENSIONS.SURVEY_WEB_INTRO_IMAGE.HEIGHT,
    RATING: IMG_DIMENSIONS.RATING.WIDTH / IMG_DIMENSIONS.RATING.HEIGHT,
    CHOICE_IMAGE_GRID: IMG_DIMENSIONS.CHOICE_IMAGE_GRID.WIDTH / IMG_DIMENSIONS.CHOICE_IMAGE_GRID.HEIGHT,
    CHOICE_IMAGE_LIST: IMG_DIMENSIONS.CHOICE_IMAGE_LIST.WIDTH / IMG_DIMENSIONS.CHOICE_IMAGE_LIST.HEIGHT,
    QUIZ_CLASSIC_START_BG: IMG_DIMENSIONS.QUIZ_CLASSIC_START_BG.WIDTH / IMG_DIMENSIONS.QUIZ_CLASSIC_START_BG.HEIGHT,
    QUIZ_CLASSIC_END_BG: IMG_DIMENSIONS.QUIZ_CLASSIC_END_BG.WIDTH / IMG_DIMENSIONS.QUIZ_CLASSIC_END_BG.HEIGHT,
    QUIZ_CLASSIC_PREVIEW_BG:
        IMG_DIMENSIONS.QUIZ_CLASSIC_PREVIEW_BG.WIDTH / IMG_DIMENSIONS.QUIZ_CLASSIC_PREVIEW_BG.HEIGHT,
    QUIZ_CLASSIC_CARD_BG: IMG_DIMENSIONS.QUIZ_CLASSIC_CARD_BG.WIDTH / IMG_DIMENSIONS.QUIZ_CLASSIC_CARD_BG.HEIGHT,
    QUIZ_DIFFERENCE_IMAGE: IMG_DIMENSIONS.QUIZ_DIFFERENCE_IMAGE.WIDTH / IMG_DIMENSIONS.QUIZ_DIFFERENCE_IMAGE.HEIGHT,
    QUIZ_CARD_ICON: IMG_DIMENSIONS.QUIZ_CARD_ICON.WIDTH / IMG_DIMENSIONS.QUIZ_CARD_ICON.HEIGHT,
    QUIZ_MEMORY_IMAGE: IMG_DIMENSIONS.QUIZ_MEMORY_IMAGE.WIDTH / IMG_DIMENSIONS.QUIZ_MEMORY_IMAGE.HEIGHT,
    QUIZ_DRAG_IMAGE: IMG_DIMENSIONS.QUIZ_DRAG_IMAGE.WIDTH / IMG_DIMENSIONS.QUIZ_DRAG_IMAGE.HEIGHT,
    QUIZ_CHALLENGE_MODAL_IMAGE:
        IMG_DIMENSIONS.QUIZ_CHALLENGE_MODAL_IMAGE.WIDTH / IMG_DIMENSIONS.QUIZ_CHALLENGE_MODAL_IMAGE.HEIGHT,
};

export const getImageUploadTooltip = (imageName, imageSizeInBytes) => (
    <>
        <span>
            {__('formValidation.aspectRatioError', {
                args: {
                    aspectRatio: ASPECT_RATIO_IMG[imageName].toFixed(1),
                },
            })}
        </span>
        <br />
        <span style={{ fontSize: '12px' }}>{__('formValidation.aspectRatioFormula')}</span>
        <br />
        <br />
        <span>
            {__('formValidation.suggestedDimensions', {
                args: IMG_DIMENSIONS[imageName],
            })}
        </span>
        <ul style={{ marginTop: '2px' }}>
            <li>width: {IMG_DIMENSIONS[imageName].WIDTH}px</li>
            <li>height: {IMG_DIMENSIONS[imageName].HEIGHT}px</li>
        </ul>
        {!!imageSizeInBytes && getFileSizeUploadTooltip(imageSizeInBytes)}
    </>
);

export const getFileSizeUploadTooltip = (imageSizeInBytes) => (
    <span>
        {__('formValidation.maxMbFile', {
            args: { mb: (imageSizeInBytes / ONE_MB).toFixed(1) },
        })}
    </span>
);

export const ONE_MB = 1024 * 1024;
export const EIGHT_MB = 8 * ONE_MB; // 8 Mb
